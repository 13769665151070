import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import UserApi from "../../helpers/userApi";

import actions from "./actions";
import $ from "jquery";

export function* getUserDetails() {
  yield takeEvery(actions.USER_PROFILE_GET, function*({}) {
    try {
      const result = yield call(UserApi.getUserProfile);

      if (result.status_code === 200) {
        yield put(actions.getUserProfileSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("User Profile Fetching Error ");
    }
  });
}

export function* updateUserRequest() {
  yield takeEvery(actions.USER_PROFILE_UPDATE, function*({ payload }) {
    try {
      const result = yield call(UserApi.updateUserProfile, payload.userData);

      if (result.status_code === 200) {
        yield put(actions.userUpdateSuccess(result.data));

        $("#messageInfo").addClass("alert alert-success");
        $("#messageInfo").attr({ style: "display:block" });
        $("#messageInfo").html(result.message);
      } else {
        $("#messageInfo").addClass("alert alert-error");
        $("#messageInfo").html(result.errors[Object.keys(result.errors)]);
        $("#messageInfo").attr({ style: "display:block" });
        $("#messageInfo").hide(5000);
      }
    } catch (error) {}
  });
}

export function* getUserSeminar() {
  yield takeEvery(actions.GET_SEMINAR, function*({}) {
    try {
      const result = yield call(UserApi.getUserSeminar);
      if (result.status_code === 200) {
        yield put(actions.userSeminarSuccess(result));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getUserNotification() {
  yield takeEvery(actions.GET_USER_NOTIFICATION, function*({}) {
    try {
      const result = yield call(UserApi.getUserNotification);

      if (result.status_code === 200) {
        yield put(actions.userNotificationSuccess(result));
      } else {
        yield put(actions.userNotificationSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getProfessionList() {
  yield takeEvery(actions.PROFESSION_LIST_GET, function*({}) {
    try {
      const result = yield call(UserApi.getProfessionList);

      if (result.status_code === 200) {
        yield put(actions.getProfessionListSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getMobileOTP() {
  yield takeEvery(actions.GET_MOBILE_OTP, function*({}) {
    try {
      const result = yield call(UserApi.getMobileOtpApi);

      if (result.status_code === 200) {
        let res = {
          data: result,
        };

        yield put(actions.getMobileOtp(res.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(updateUserRequest),
    fork(getUserDetails),
    fork(getUserNotification),
    fork(getProfessionList),
    fork(getMobileOTP),
    fork(getUserSeminar),
  ]);
}
