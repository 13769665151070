const actions = {
  USER_PROFILE_GET: "USER_PROFILE_GET",
  USER_PROFILE_GET_SUCCESS: "USER_PROFILE_GET_SUCCESS",
  USER_PROFILE_UPDATE: "USER_PROFILE_UPDATE",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  GET_USER_NOTIFICATION: "GET_USER_NOTIFICATION",
  USER_NOTIFICATION_SUCCESS: "USER_NOTIFICATION_SUCCESS",
  PROFESSION_LIST_GET: "PROFESSION_LIST_GET",
  PROFESSION_LIST_GET_SUCCESS: "PROFESSION_LIST_GET_SUCCESS",
  GET_MOBILE_OTP: "GET_MOBILE_OTP",
  GET_SEMINAR: "GET_SEMINAR",
  USER_SEMINAR_SUCCESS: "USER_SEMINAR_SUCCESS",

  getUserProfile: () => ({
    type: actions.USER_PROFILE_GET,
    payload: {},
  }),
  
  getUserProfileSuccess: (result) => ({
    type: actions.USER_PROFILE_GET_SUCCESS,
    payload: { result },
  }),

  userProfileUpdate: (userData) => ({
    type: actions.USER_PROFILE_UPDATE,
    payload: { userData },
  }),

  userUpdateSuccess: (result) => ({
    type: actions.USER_UPDATE_SUCCESS,
    payload: { result },
  }),

  userNotifications: () => ({
    type: actions.GET_USER_NOTIFICATION,
    payload: {},
  }),

  userNotificationSuccess: (result) => ({
    type: actions.USER_NOTIFICATION_SUCCESS,
    payload: { result },
  }),

  userSeminar: () => ({
    type: actions.GET_SEMINAR,
    payload: {},
  }),

  userSeminarSuccess: (result) => ({
    type: actions.USER_SEMINAR_SUCCESS,
    payload: { result },
  }),

  getProfessionList: (result) => ({
    type: actions.PROFESSION_LIST_GET,
    payload: {},
  }),

  getProfessionListSuccess: (result) => ({
    type: actions.PROFESSION_LIST_GET_SUCCESS,
    payload: { result },
  }),

  getMobileOtp: (result) => ({
    type: actions.GET_MOBILE_OTP,
    payload: { result },
  }),
};
export default actions;
