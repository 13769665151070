import React, { Component } from "react";
import { connect } from "react-redux";

import AuthAction from "../../redux/auth/actions";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: "",
      formIsValid: true,
      errorMessage: "",
      mailConfirm: false,
      isLoaderVisible: "none",
    };
  }

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleValidate = () => {
    let errors = {};

    let formIsValid = true;
    this.setState({ errors });
    if (this.state.email == undefined) {
      formIsValid = false;
      errors["email"] = "email";
    }
    this.setState({ formIsValid });
    return formIsValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidate()) {
      this.setState({ isLoaderVisible: "block" });
      this.props.forgotpassword({ email: this.state.email });
    }
  };
  handleProceed = () => {
    this.props.history.push("/");
  };

  componentDidUpdate(prevprops) {
    if (
      prevprops.forgotPasswordResponse !== this.props.forgotPasswordResponse
    ) {
      if (
        this.props.forgotPasswordResponse &&
        this.props.forgotPasswordResponse.status_code == 200
      ) {
        this.setState({ mailConfirm: true, isLoaderVisible: "none" });
      } else if (
        this.props.forgotPasswordResponse &&
        this.props.forgotPasswordResponse.response &&
        this.props.forgotPasswordResponse.response.data.code == 422
      ) {
        this.setState({
          errorMessage: this.props.forgotPasswordResponse.response.data.errors
            .email[0],
          isLoaderVisible: "none",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      } else if (
        this.props.forgotPasswordResponse &&
        this.props.forgotPasswordResponse.status_code == 404
      ) {
        this.setState({
          // full_name: "",
          // email: "",
          // phone: "",
          // digit_1: "",
          // digit_2: "",
          // digit_3: "",
          mailConfirm: false,
          errorMessage: this.props.forgotPasswordResponse.message,
          isLoaderVisible: "none",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      }
    }
  }
  render() {
    const {
      email,
      errorMessage,
      errors,
      mailConfirm,
      formIsValid,
      isLoaderVisible,
    } = this.state;
    return (
      <>
        <header>
          <nav
            class="navbar navbar-expand-md custom-navbar login-bg"
            id="headerdiv"
          >
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img src="/dist/img/logo.png" class="img-fluid" alt="Logo" />
              </a>
            </div>
          </nav>
        </header>
        {/* modal loader */}
        <div className="modal" style={{ display: isLoaderVisible }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body loaderBox" style={{ margin: "auto" }}>
                <h3>Please wait...</h3>
                <div className="loader"></div>
              </div>
            </div>
          </div>
        </div>
        {/* loader ends */}
        {/* Confimation Modal */}
        {mailConfirm && (
          <div className="modal" style={{ display: "block" }} id="modalId">
            <div className="modal-dialog confirm-dialog modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <button
                    type="button"
                    className="close customClose"
                    data-dismiss="modal"
                    onClick={() => this.setState({ mailConfirm: false })}
                  >
                    &times;
                  </button>
                  <div className="row">
                    <div className="col-md-12 confirm-content">
                      <h2>Confirmation !</h2>
                      <p>
                        Thanks! password reset link has been shared to provided
                        email id
                      </p>
                    </div>
                    <div className="col-md-12 text-center btn-group-b">
                      <button
                        type="button"
                        className="btn-confirm"
                        data-dismiss="modal"
                        onClick={this.handleProceed}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Modal Ends */}
        <div class="login-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div class="login-box">
                  <div class="leftSec">
                    <img
                      src="/dist/img/reset-password.png"
                      class="img-fluid"
                      alt="login Image"
                    />
                  </div>
                  <div class="rightSec">
                    <form onSubmit={this.handleSubmit}>
                      <h2>Reset Password</h2>
                      <span className="error-msg">{errorMessage}</span>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>E-Mail Address</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.email == "email" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i class="fas fa-envelope"></i>
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                value={email}
                                name="email"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <button class="btn-login" onClick={this.handleSubmit}>
                            Submit
                          </button>
                          <button
                            class="btn-back"
                            onClick={() => this.props.history.push("/")}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    state,
    forgotPasswordResponse: state.Auth.forgotdata,
  }),
  { ...AuthAction }
)(ForgotPassword);
