import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import dashboardSagas from "./dashboard/saga";
import userSagas from "./user/saga";
import qversityCourseSagas from "./course/saga";
import instructorDetailSaga from "./instructorDetails/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    dashboardSagas(),
    userSagas(),
    qversityCourseSagas(),
    instructorDetailSaga(),
  ]);
}
