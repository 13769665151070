/*const siteUrl = {
    site_url: "http://localhost:90/",
    web_url: "http://localhost:3000/",
};*/

const siteUrl = {
    site_url: "https://compilerlms.qversity.com/",
    web_url: "https://compiler.qversity.com/",
};

export { siteUrl };