import React, { Component } from "react";
import { connect } from "react-redux";

import AuthAction from "../../redux/auth/actions";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      email: "",
      phone: "",
      digit_1: "",
      digit_2: "",
      digit_3: "",
      errors: "",
      formIsValid: true,
      mailConfirm: false,
      errorMessage: null,
      isLoaderVisible: "none",
    };
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleValidate = () => {
    let errors = {};
    const { full_name, email, phone, digit_1, digit_2, digit_3 } = this.state;
    let formIsValid = true;
    this.setState({ errors });
    if (digit_1 == "") {
      formIsValid = false;
      errors["digit_1"] = "digit_1";
    } else if (digit_2 == "") {
      formIsValid = false;
      errors["digit_2"] = "digit_2";
    } else if (digit_3 == "") {
      formIsValid = false;
      errors["digit_3"] = "digit_3";
    } else if (full_name == "") {
      formIsValid = false;
      errors["full_name"] = "FullName";
    } else if (email == "") {
      formIsValid = false;
      errors["email"] = "Email";
    } else if (phone == "") {
      formIsValid = false;
      errors["phone"] = "phone";
    }
    this.setState({ formIsValid });
    return formIsValid;
  };

  handleSignup = (event) => {
    const { full_name, email, phone, digit_1, digit_2, digit_3 } = this.state;
    event.preventDefault();
    if (this.handleValidate()) {
      this.setState({ isLoaderVisible: "block" });
      this.props.UserSignup({
        name: full_name,
        email: email,
        mobile: phone,
        user_code: `${digit_1}-${digit_2}-${digit_3}`,
      });
    }
  };

  componentDidUpdate(previousProps) {
    if (previousProps.isSignupSucces != this.props.isSignupSucces) {
      if (this.props.isSignupSucces != null) {
        if (
          this.props.isSignupSucces &&
          this.props.isSignupSucces.status_code == 200
        ) {
          this.setState({
            full_name: "",
            email: "",
            phone: "",
            digit_1: "",
            digit_2: "",
            digit_3: "",
            mailConfirm: true,
            isLoaderVisible: "none",
          });
        } else if (
          this.props.isSignupSucces &&
          this.props.isSignupSucces.status_code &&
          this.props.isSignupSucces.status_code == 404
        ) {
          this.setState({
            // full_name: "",
            // email: "",
            // phone: "",
            // digit_1: "",
            // digit_2: "",
            // digit_3: "",
            mailConfirm: false,
            errorMessage: this.props.isSignupSucces.message,
            isLoaderVisible: "none",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        } else if (
          this.props.isSignupSucces &&
          this.props.isSignupSucces.error &&
          this.props.isSignupSucces.error.response &&
          this.props.isSignupSucces.error.response.data.code == 422
        ) {
          console.log(this.props.isSignupSucces.error.response.data.errors);
          let errormsg = '';
          if (this.props.isSignupSucces.error.response.data.errors.email != undefined) {
            errormsg = this.props.isSignupSucces.error.response.data.errors.email[0]+"\n";
          }
          if (this.props.isSignupSucces.error.response.data.errors.mobile != undefined) {
            errormsg += this.props.isSignupSucces.error.response.data.errors.mobile[0];
          }

          this.setState({
            // full_name: "",
            // email: "",
            // phone: "",
            // digit_1: "",
            // digit_2: "",
            // digit_3: "",
            mailConfirm: false,
            errorMessage: errormsg,
            isLoaderVisible: "none",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      }
    }
  }

  handleProceed = () => {
    this.setState({ setMailConfirm: false });
    this.props.history.push("/");
  };

  handleLoginPage = () => {
    this.props.history.push("/");
  };

  render() {
    const {
      full_name,
      email,
      phone,
      digit_1,
      digit_2,
      digit_3,
      mailConfirm,
      errorMessage,
      formIsValid,
      errors,
      isLoaderVisible,
    } = this.state;
    return (
      <>
        <header>
          <nav
            class="navbar navbar-expand-md custom-navbar login-bg"
            id="headerdiv"
          >
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img src="/dist/img/logo.png" class="img-fluid" alt="Logo" />
              </a>
            </div>
          </nav>
        </header>
        {/* loader */}
        <div className="modal" style={{ display: isLoaderVisible }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body loaderBox" style={{ margin: "auto" }}>
                <h3>Please wait...</h3>
                <div className="loader"></div>
              </div>
            </div>
          </div>
        </div>
        {/* loader ends */}
        {/* Confimation Modal */}
        {mailConfirm && (
          <div className="modal" style={{ display: "block" }} id="modalId">
            <div className="modal-dialog confirm-dialog modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <button
                    type="button"
                    className="close customClose"
                    data-dismiss="modal"
                    onClick={() => this.setState({ mailConfirm: false })}
                  >
                    &times;
                  </button>
                  <div className="row">
                    <div className="col-md-12 confirm-content">
                      <h2>Confirmation !</h2>
                      <p>
                        Thanks for registration, your login credentials has been
                        shared on provided email
                      </p>
                    </div>
                    <div className="col-md-12 text-center btn-group-b">
                      <button
                        type="button"
                        className="btn-confirm"
                        data-dismiss="modal"
                        onClick={this.handleProceed}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Modal Ends */}
        <div class="login-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div class="login-box">
                  <div class="leftSec">
                    <img
                      src="/dist/img/signup.png"
                      class="img-fluid"
                      alt="login Image"
                    />
                  </div>
                  <div class="rightSec">
                    <form onSubmit={this.handleSignup}>
                      <h2>Signup Here</h2>
                      <div class="row">
                        <div class="col-lg-12">
                          <span className="error-msg">{errorMessage}</span>
                          <div class="form-group">
                            <label>Activation Code</label>
                            <div class="digit-group">
                              <input
                                type="text"
                                class="form-control"
                                id="digit_1"
                                name="digit_1"
                                maxlength="3"
                                value={digit_1}
                                onChange={this.handleChange}
                                style={{
                                  border:
                                    !formIsValid &&
                                    errors.digit_1 == "digit_1" &&
                                    "1px solid red",
                                }}
                              />
                              <input
                                type="text"
                                class="form-control"
                                id="digit_2"
                                name="digit_2"
                                maxlength="3"
                                value={digit_2}
                                onChange={this.handleChange}
                                style={{
                                  border:
                                    !formIsValid &&
                                    errors.digit_2 == "digit_2" &&
                                    "1px solid red",
                                }}
                              />
                              <input
                                type="text"
                                class="form-control"
                                id="digit_3"
                                name="digit_3"
                                value={digit_3}
                                maxlength="3"
                                onChange={this.handleChange}
                                style={{
                                  border:
                                    !formIsValid &&
                                    errors.digit_3 == "digit_3" &&
                                    "1px solid red",
                                }}
                              />
                            </div>
                          </div>
                          <div class="digit-example">
                            <span>xxx</span>
                            <span>xxx</span>
                            <span>xxx</span>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div className="form-group ">
                            <label>Full Name</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.full_name == "FullName" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i class="fas fa-user"></i>
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                name="full_name"
                                value={full_name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div className="form-group ">
                            <label>E-Mail Address</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.email == "Email" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i class="fas fa-envelope"></i>
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div className="form-group ">
                            <label>Phone Number</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.phone == "phone" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i class="fas fa-phone r-90"></i>
                              </span>
                              <input
                                type="text"
                                maxlength="10"
                                pattern="[0-9]{1}[0-9]{9}"
                                class="form-control"
                                name="phone"
                                value={phone}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <button class="btn-login" onClick={this.handleSignup}>
                            Sign Up
                          </button>
                        </div>
                        <div class="col-lg-12">
                          <p class="new-user">
                            Already a member?
                            <span onClick={this.handleLoginPage}>Log in</span>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    state,
    isSignupSucces: state.Auth.signupData,
  }),
  { ...AuthAction }
)(Registration);
