import $ from "jquery";

var errorObj = {};

export const validationObj = {
  isValidEmail: (value, fieldName, parentId, emptyChk) => {
    errorObj[fieldName] = "";
    $("#" + parentId + " ." + fieldName).removeClass("has_error");
    if (emptyChk === 1 && value === false) {
      errorObj[fieldName] =
        fieldName.charAt(0).toUpperCase() +
        fieldName.slice(1) +
        " cannot be empty";
      $("#" + parentId + " ." + fieldName).addClass("has_error");
    } else if (typeof value !== "undefined" && value !== "") {
      let lastAtPos = value.lastIndexOf("@");
      let lastDotPos = value.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          value.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          value.length - lastDotPos > 2
        )
      ) {
        errorObj[fieldName] = "Email is not valid";
        $("#" + parentId + " ." + fieldName).addClass("has_error");
      }
    }
    return errorObj;
  },

  isValidPhone: (value, fieldName, parentId, emptyChk) => {
    errorObj[fieldName] = "";
    $("#" + parentId + " ." + fieldName).removeClass("has_error");
    if (
      (emptyChk === 1 && value === false) ||
      value === "" ||
      value === null ||
      value === undefined
    ) {
      errorObj[fieldName] =
        fieldName.charAt(0).toUpperCase() +
        fieldName.slice(1) +
        " cannot be empty";
      $("#" + parentId + " ." + fieldName).addClass("has_error");
    } else if (typeof value !== "undefined" && value !== "") {
      let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

      if (!value.match(phoneno)) {
        errorObj[fieldName] = "Phone number not valid";
        $("#" + parentId + " ." + fieldName).addClass("has_error");
      }
    }
    return errorObj;
  },

  isValidName: (value, fieldName, parentId, emptyChk) => {
    errorObj[fieldName] = "";
    $("#" + parentId + " ." + fieldName).removeClass("has_error");
    if (emptyChk === 1 && value === false) {
      errorObj[fieldName] =
        fieldName.charAt(0).toUpperCase() +
        fieldName.slice(1) +
        " cannot be empty";
      $("#" + parentId + " ." + fieldName).addClass("has_error");
    } else if (typeof value !== "undefined" && value !== "") {
      if (value !== null && !value.match(/^[a-zA-Z\s]+$/)) {
        errorObj[fieldName] = "Please provide correct format";
        $("#" + parentId + " ." + fieldName).addClass("has_error");
      }
    }
    return errorObj;
  },

  isEmpty: (value, fieldName, parentId) => {
    errorObj[fieldName] = "";
    $("#" + parentId + " ." + fieldName).removeClass("has_error");

    if (value === false) {
      errorObj[fieldName] =
        fieldName.charAt(0).toUpperCase() +
        fieldName.slice(1) +
        " cannot be empty";
      $("#" + parentId + " ." + fieldName).addClass("has_error");
    }

    return errorObj;
  },

  numericChk: (value, fieldName, parentId) => {
    // let errors = true;
    $("#" + parentId + " ." + fieldName).removeClass("has_error");
    if (isNaN(value)) {
      errorObj[fieldName] =
        fieldName.charAt(0).toUpperCase() + fieldName.slice(1) + " not valid";
      $("#" + parentId + " ." + fieldName).addClass("has_error");
    }
    return errorObj;
  },

  isValidZipCode: (value, fieldName, parentId, emptyChk) => {
    errorObj[fieldName] = "";
    $("#" + parentId + " ." + fieldName).removeClass("has_error");
    if (emptyChk === 1 && value === false) {
      errorObj[fieldName] =
        fieldName.charAt(0).toUpperCase() +
        fieldName.slice(1) +
        " cannot be empty";
      $("#" + parentId + " ." + fieldName).addClass("has_error");
    } else if (typeof value !== "undefined" && value !== "") {
      if (!String(value).match(/^[1-9][0-9]{4,5}$/)) {
        errorObj[fieldName] =
          fieldName.charAt(0).toUpperCase() + fieldName.slice(1) + " not valid";
        $("#" + parentId + " ." + fieldName).addClass("has_error");
      }
    }
    return errorObj;
  },
};
