import React, { Component } from "react";

class ComingSoon extends Component {
  render() {
    return (
      <>
        <header>
          <nav
            class="navbar navbar-expand-md custom-navbar login-bg"
            id="headerdiv"
          >
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img src="/dist/img/logo.png" class="img-fluid" alt="Logo" />
              </a>
            </div>
          </nav>
        </header>

        <div class="login-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 ">
                <div class="landing-page">
                  <div class="landing-text-inner">
                    <h1>Coming Soon</h1>
                    <h3 class="sub-title"> you are awesome</h3>
                    {/* <a class="btn" href="">
                      View More
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ComingSoon;
