import { course, header } from "../config";
import SiteHelper from "./siteHelper";
class CourseApi extends SiteHelper {
  getCourseDetail = async (course_slug) => {
   
    let courseDeatilUrl = course.courseDetail + "/" + course_slug;
    return await fetch(courseDeatilUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        this.checkToken(res);
        return res;
      })
      .catch((error) => ({ error }));
  };

  getContentDetail = async (content_id) => {
    let courseDeatilUrl = course.courseDetail + "/" + content_id;
    return await fetch(courseDeatilUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };
}
export default new CourseApi();
