import { Map } from 'immutable';
import actions from './actions';
/**
 * Things you should never do inside a reducer:
1. Mutate its arguments;
2. Perform side effects like API calls and routing transitions;
3. Call non-pure functions, e.g. Date.now() or Math.random().
 */


const initState = new Map({
  success: false,
  msg: '',
  result: [],
  notificationResult: [],
  userProfileResult: [],
  professionListResult: [],
  otp: []
});

export default function userReducer(state = initState, action) {
 
  switch (action.type) {

    case actions.USER_PROFILE_GET_SUCCESS:
      return state
        .set('userProfileResult', action.payload.result)
        .set('success', action.success)
        .set('msg', '');

    case actions.USER_UPDATE_SUCCESS:
      return state
        .set('msg', action.payload.result)
        .set('success', action.success)
        .set('userProfileResult', action.payload.result);

    case actions.USER_NOTIFICATION_SUCCESS:
      return state
        .set('notificationResult', action.payload.result)
        .set('noti_success', action.success);

    case actions.USER_SEMINAR_SUCCESS:
      return state
        .set('seminarResult', action.payload.result)
        .set('seminar_success', action.success);

    case actions.PROFESSION_LIST_GET_SUCCESS:

      return state
        .set('professionListResult', action.payload.result)
        .set('success', action.success)
        .set('msg', '');

    case actions.GET_MOBILE_OTP:

      return state
        .set('getMobileOtpData', action.payload.result)
        .set('success', action.success)
        .set('msg', '');

    default:
      return state;
  }
}
