import React from "react";

const SkeltonLoader = () => {
  return (
    <div className="container" style={{ display: "flex" }}>
      <div className="section_card" style={{ marginRight: "10px" }}></div>
      <div className="section_card" style={{ marginRight: "10px" }}></div>
      <div className="section_card"></div>
    </div>
  );
};

export default SkeltonLoader;
