import {
  teacherUnitDetails,
  assignment,
  quiz,
  attendance,
  header,
} from "../config";
import SiteHelper from "./siteHelper";
import axios from "axios";

class InstructorApi extends SiteHelper {
  getUnitTypeDetail = async () => {
    let courseDeatilUrl = teacherUnitDetails.getUnit;
    return await fetch(courseDeatilUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getLanguageDetail = async () => {
    let languageListUrl = teacherUnitDetails.language;
    return await fetch(languageListUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getSectionDetail = async () => {
    const courseid = localStorage.getItem("courseid");
    let courseDeatilUrl = `${teacherUnitDetails.getAllSectionUnit}?course_id=${courseid}`;
    return await fetch(courseDeatilUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getBatchDetail = async () => {
    const courseid = localStorage.getItem("courseid");
    let instructorBatchUrl = `${teacherUnitDetails.getAllBatch}?course_id=${courseid}`;
    return await fetch(instructorBatchUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getHostDetail = async () => {
    let instructorHostUrl = teacherUnitDetails.getAllHostId;
    return await fetch(instructorHostUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getQuizDetail = async () => {
    let instructorQuizUrl = quiz.quizes;
    return await fetch(instructorQuizUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getInstructorQuizList = async () => {
    let instructorQuizUrl = quiz.getInstructorQuiz;
    return await fetch(instructorQuizUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getQuizQuestionDetails = async () => {
    let instructorQuizUrl = quiz.notAssignedQuiz;
    return await fetch(instructorQuizUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getQuizAssesmentDetail = async (quizId, user_Id) => {
    let quizAssesmentUrl = `${quiz.studentAssessmentDetails}?quizId=${quizId}&userId=${user_Id}`;
    return await fetch(quizAssesmentUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getAssigmentDetail = async () => {
    let instructorAssigmentUrl = assignment.assignments;
    return await fetch(instructorAssigmentUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getMyscheduleDetail = async () => {
    let instructorMyscheduletUrl = assignment.mySchedule;
    return await fetch(instructorMyscheduletUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getQuizQuestionDetail = async (quizId) => {
    let quizQuestionUrl = `${quiz.quizQuestion}?quizId=${quizId}`;
    return await fetch(quizQuestionUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getQuizDetailData = async (quiz_id) => {
    let instructorQuizDetailsUrl = `${quiz.quizeDetails}?quiz_id=${quiz_id}`;
    return await fetch(instructorQuizDetailsUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getViewAssigmentData = async (assignmentId) => {
    let instructorViewAssigmentUrl = `${assignment.assignmentDetails}?assignment_id=${assignmentId}`;

    return await fetch(instructorViewAssigmentUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getStudentListQuizData = async (quizId, batchId) => {
    let studentListQuizUrl = `${quiz.quizStudents}?quizId=${quizId}&batchId=${batchId}`;

    return await fetch(studentListQuizUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getVerifyDetail = async (user_assignment_id) => {
    let instructorVerifyAssigmentUrl = `${assignment.verifyAssignment}?user_assignment_id=${user_assignment_id}`;

    return await fetch(instructorVerifyAssigmentUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getAttendanceData = async () => {
    let instructorAttendanceUrl = attendance.getAttendanceData;

    return await fetch(instructorAttendanceUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  postSectionDetail = async (data) => {
    let instructorAddSectionUrl = teacherUnitDetails.addSection;
    return await axios
      .post(
        instructorAddSectionUrl,
        {
          ...data,
        },
        {
          headers: header,
        }
      )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  postNotificationDetail = async (data) => {
    let instructorNotificationUrl = teacherUnitDetails.sendStudentNotification;

    return await axios
      .post(
        instructorNotificationUrl,
        {
          ...data,
        },
        {
          headers: header,
        }
      )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  postInstructorDescription = async (data) => {
    let instructorDescriptionUrl = assignment.reviewAssignment;

    return await axios
      .post(
        instructorDescriptionUrl,
        {
          ...data,
        },
        {
          headers: header,
        }
      )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  postInstructorVerifyQuizData = async (data) => {
    let instructorVerifyQuizUrl = quiz.reviewQuiz;

    return await axios
      .post(
        instructorVerifyQuizUrl,
        {
          ...data,
        },
        {
          headers: header,
        }
      )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };
}

export default new InstructorApi();
