import { site_url, user_token } from "../config";

class SiteHelper {
  constructor(props) {
    if (user_token === null || user_token === "") {
      //window.location.href = site_url;
    }
  }
  checkToken(res) {
    if (res.status_code === 400) {
      window.location.href = site_url;
    }
  }

  requestLog(req) {}

  responseLog(res) {}
}
export default SiteHelper;
