const actions = {
  COURSE_DETAIL: "COURSE_DETAIL",
  CONTENT_DETAIL: "CONTENT_DETAIL",
  COURSE_DETAIL_SUCCESS: "COURSE_DETAIL_SUCCESS",
  CONTENT_DETAIL_SUCCESS: "CONTENT_DETAIL_SUCCESS",

  courseDetail: (course_slug) => ({
    type: actions.COURSE_DETAIL,
    payload: { course_slug },
  }),

  contentDetail: (content_id) => ({
    type: actions.CONTENT_DETAIL,
    payload: { content_id },
  }),

  courseDetailSuccess: (result) => ({
    type: actions.COURSE_DETAIL_SUCCESS,
    payload: { result },
  }),

  contentDetailSuccess: (result) => ({
    type: actions.COURSE_DETAIL_SUCCESS,
    payload: { result },
  }),
};
export default actions;
