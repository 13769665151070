import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { siteUrl } from "../../siteConfig";

class SidebarMenu extends Component {
  render() {
    let getEmailId = localStorage.getItem("emailId");
    let getRole_Name = localStorage.getItem("role_name");

    let classes = "";
    if (siteUrl.site_url !== "http://qa.qversity.com/") {
      classes = "dummyFeatureHide";
    }

    return (
      <div>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu" data-widget="tree">
              <li className="header" id="dashboard">
                <Link to="/dashboard" className="dashboard_anchor">
                  <img src="/dist/img/Dashboard.png" alt="dashboard" />
                  Dashboard
                </Link>
              </li>
            </ul>
          </section>
        </aside>
      </div>
    );
  }
}
export default connect()(SidebarMenu);
