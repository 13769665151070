import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { SizeMe } from 'react-sizeme';
import appActions from "../../redux/app/actions";
import { course, header } from "../../config";
import { connect } from "react-redux";

import axios from "axios";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { pdfSliderOnCardSuccess } = appActions;


class PdfReader extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    UnitDetail: {},
    ext: "",
    isVisibleCard: "none",
    isModalVisible: false,
    stepCount: 1,
    questionAnswer: "",
    wrongAns: "none",
    isCompilerModal: false,
  };

  componentDidMount() {
    if (this.props.url && this.props.url.current_page) {
      this.setState({ pageNumber: parseInt(this.props.url.current_page) });
    } else {
      this.setState({ pageNumber: 1 });
    }
  }

  componentWillReceiveProps(nextprops, nextState) {
    if (nextprops.url.current_page != null) {
      this.setState({ pageNumber: parseInt(nextprops.url.current_page) });
    } else {
      this.setState({ pageNumber: 1 });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    if (numPages) {
      this.setState({ numPages });
    }
  };

  goToPrevPage = () => {
    this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  };

  goToNextPage = () => {
    this.setState({ pageNumber: parseInt(this.state.pageNumber) + 1 }, () =>
      this.saveProgress()
    );
  };

  saveProgress = () => {
    const data = {
      unit_id: this.props.url.id,
      current_page: this.state.pageNumber,
      total_page: this.state.numPages,
    };
    var milliseconds = new Date().getTime();
debugger;
    axios
      .post(`${course.sectionUnitProgress}?c=${milliseconds}`, data, {
        headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("u$tOken"),
		},
      })
      .then((res) => console.log(res));
  };

  nextQusetion = () => {
    this.setState((state) => ({ stepCount: state.stepCount + 1 }));
  };

  render() {
    const UnitDetail = this.props.url;

    let extension = "";
    let isPptFile = "";
    let fileUrl = "";
    let downloadfileUrl = UnitDetail.url;
    if (UnitDetail.url != null) {
      extension = UnitDetail.url.slice(
        Math.max(0, UnitDetail.url.lastIndexOf(".")) || Infinity
      );
      if (extension === ".pdf") {
        fileUrl = UnitDetail.url + "#toolbar=0";
      }
      if (extension === ".pptx") {
        fileUrl = `${UnitDetail.url}`;
        // + "#toolbar=0";

        isPptFile = true;
      }
    }

    const { pageNumber, numPages } = this.state;

    return (
      <>
        {!downloadfileUrl && (
          <div>
            <h1>File is not found</h1>
          </div>
        )}
        {downloadfileUrl && (
          <div class="compiler-details compiler-pdf-wrapper">
            <h2>{UnitDetail.title}</h2>
            <div class="que-box">
              <div class="pdfSec">
                <div
                  className="document_pdf"
                  style={{
                    overflowY: 'hidden',
                    width: "100%",
                  }}
                >
                  <div className={"pull-left pdf-box"}>
                    {isPptFile ? (
                      <iframe
                        src={fileUrl}
                        width="830"
                        height="400"
                        title="ppt"
                      />
                    ) : (
                      <Document
                        file={fileUrl}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                      >
                          <Page className="scrollBarList" pageNumber={pageNumber} width={800}/>
                      </Document>
                    )}
                  </div>
                </div>

                {/* <div className="col-lg-6 text-center">
              <button
                className={"btn btn-blueColor"}
                data-nextsec={"abhishek"}
                data-entityid={this.props.entityId}
                onClick={this.props.onClick}
              >
                Finish
              </button>
                    </div> */}

                <div className="pdf_control">
                  <div className="button_prev">
                    {(() => {
                      if (pageNumber > 1) {
                        return (
                          <span
                            onClick={this.goToPrevPage}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-chevron-left"></i>
                            {/* <i class="fa fa-angle-left" aria-hidden="true" ></i> */}
                          </span>
                        );
                        // <button onClick={this.goToPrevPage}>Prev</button>
                      }
                    })()}
                  </div>
                  <div className="pdf_reader">
                    <div className={"quiz_count"}>
                      <h3>
                        <span>
                          {pageNumber}/{numPages}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="button_next">
                    {(() => {
                      if (pageNumber < numPages) {
                        return (
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={this.goToNextPage}
                          >
                            <i className="fas fa-chevron-right"></i>
                          </span>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
   
    pdfSlideViewArrow: state.App.pdfSliderValue,
  }),
  { pdfSliderOnCardSuccess }
)(PdfReader);
