import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import InstructorApi from "../../helpers/instructorApi";

import actions from "./actions";

export function* getUnitTypeDetails() {
  yield takeEvery(actions.INSTRUCTOR_UNIT_TYPE, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getUnitTypeDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.instructorUnitTypeSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getLanguageLists() {
  yield takeEvery(actions.LANGUAGE_LIST, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getLanguageDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.languageListSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getUnitList() {
  yield takeEvery(actions.INSTRUCTOR_UNIT_LIST, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getSectionDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.instructorUnitListSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getVerifyData() {
  yield takeEvery(actions.INSTRUCTOR_VERIFY_ASSIGNMENT, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.getVerifyDetail,
        payload.user_assignment_id
      );

      if (result.status_code === 200) {
        yield put(actions.verifyAssignmentSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getBatchList() {
  yield takeEvery(actions.INSTRUCTOR_BATCHES, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getBatchDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.instructorBatchSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getMyscheduleList() {
  yield takeEvery(actions.INSTRUCTOR_MYSCHEDULE, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getMyscheduleDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.myScheduleListSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllHost() {
  yield takeEvery(actions.INSTRUCTOR_HOST_ID, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getHostDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.instructorHostIdSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllQuiz() {
  yield takeEvery(actions.INSTRUCTOR_QUIZ_LIST, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getQuizDetail, payload);

      if (result.status_code === 200) {
        yield put(actions.quizListsSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllInstructorQuiz() {
  yield takeEvery(actions.INSTRUCTOR_GET_QUIZ, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getInstructorQuizList, payload);

      if (result.status_code === 200) {
        yield put(actions.getInstructorQuizListSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllQuestionQuiz() {
  yield takeEvery(actions.INSTRUCTOR_QUIZ_QUESTION_LIST, function*({
    payload,
  }) {
    try {
      const result = yield call(InstructorApi.getQuizQuestionDetails, payload);

      if (result.status_code === 200) {
        yield put(actions.quizListQuestionSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllStudentAssesment() {
  yield takeEvery(actions.INSTRUCTOR_STUDENT_ASSESMENT, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.getQuizAssesmentDetail,
        payload.quizId,
        payload.user_Id
      );

      if (result.status_code === 200) {
        yield put(actions.QuizAssesmentSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllQuizQuestion() {
  yield takeEvery(actions.INSTRUCTOR_QUIZ_QUESTION, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.getQuizQuestionDetail,
        payload.quizId
      );

      if (result.status_code === 200) {
        yield put(actions.QuizQuestionSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getAllAssigment() {
  yield takeEvery(actions.INSTRUCTOR_ASSIGNMENT_DETAIL, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.getAssigmentDetail, payload);
      if (result.status_code === 200) {
        yield put(actions.assignmentDetailsSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getQuizDetailList() {
  yield takeEvery(actions.INSTRUCTOR_QUIZ_DETAILS, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.getQuizDetailData,
        payload.quiz_id
      );

      if (result.status_code === 200) {
        yield put(actions.QuizDetailSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getViewAssigment() {
  yield takeEvery(actions.INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL, function*({
    payload,
  }) {
    try {
      const result = yield call(
        InstructorApi.getViewAssigmentData,
        payload.assignmentId
      );
      if (result.status_code === 200) {
        yield put(actions.viewAssigmentDataSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getStudentListQuiz() {
  yield takeEvery(actions.INSTRUCTOR_STUDENT_LIST_QUIZ, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.getStudentListQuizData,
        payload.quizId,
        payload.batchId
      );
      if (result.status_code === 200) {
        yield put(actions.studentListQuizDetailSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getattendanceList() {
  yield takeEvery(actions.INSTRUCTOR_ATTENDANCE_LIST, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.getAttendanceData,
        payload.assignmentId
      );
      if (result.status_code === 200) {
        yield put(actions.attendanceListSuccess(result.data));
      } else {
        yield put(actions.attendanceListSuccess(result.data));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* postAllSection() {
  yield takeEvery(actions.INSTRUCTOR_ADD_SECTION, function*({ payload }) {
    try {
      const result = yield call(InstructorApi.postSectionDetail, payload.data);

      if (result.status_code === 200) {
        yield put(actions.InstructorAddSectionSuccess(result));
      } else {
        yield put(actions.InstructorAddSectionSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* postAllNotification() {
  yield takeEvery(actions.INSTRUCTOR_STUDENT_NOTIFICATION, function*({
    payload,
  }) {
    try {
      const result = yield call(
        InstructorApi.postNotificationDetail,
        payload.data
      );

      if (result.status_code === 200) {
        yield put(actions.sendStudentNotificationSuccess(result));
      } else {
        yield put(actions.sendStudentNotificationSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* postInstructorComment() {
  yield takeEvery(actions.INSTRUCTOR_ASSIGNMENT_COMMENT, function*({
    payload,
  }) {
    try {
      const result = yield call(
        InstructorApi.postInstructorDescription,
        payload.data
      );

      if (result.status_code === 200) {
        yield put(actions.checkAssignmentCommentSuccess(result));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* postInstructorVerifyQuiz() {
  yield takeEvery(actions.INSTRUCTOR_VERIFY_QUIZ, function*({ payload }) {
    try {
      const result = yield call(
        InstructorApi.postInstructorVerifyQuizData,
        payload.data
      );

      if (result.status_code === 200) {
        yield put(actions.verifyQuizSuccess(result));
      } else {
        yield put(actions.verifyQuizSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUnitTypeDetails)]);
  yield all([fork(getUnitList)]);
  yield all([fork(getBatchList)]);
  yield all([fork(getAllHost)]);
  yield all([fork(getAllQuiz)]);
  yield all([fork(postAllSection)]);
  yield all([fork(postAllNotification)]);
  yield all([fork(getAllAssigment)]);
  yield all([fork(getViewAssigment)]);
  yield all([fork(getVerifyData)]);
  yield all([fork(postInstructorComment)]);
  yield all([fork(getattendanceList)]);
  yield all([fork(getMyscheduleList)]);
  yield all([fork(getQuizDetailList)]);
  yield all([fork(getAllQuizQuestion)]);
  yield all([fork(getStudentListQuiz)]);
  yield all([fork(getAllStudentAssesment)]);
  yield all([fork(postInstructorVerifyQuiz)]);
  yield all([fork(getAllQuestionQuiz)]);
  yield all([fork(getAllInstructorQuiz)]);
  yield all([fork(getLanguageLists)]);
}
