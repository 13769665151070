export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  CLOSE_ALL: "CLOSE_ALL",
  PDF_SLIDE_LEFT: "SLIDE_PDF_RIGHT",
  UPCOMING_CALENDAR_DATA: "UPCOMING_CALENDAR_DATA",
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== "DesktopView";
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: (openKeys) => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  closeAll: () => ({ type: actions.CLOSE_ALL }),

  pdfSliderOnCardSuccess: (pdfSliderValue) => ({
    type: actions.PDF_SLIDE_LEFT,
    pdfSliderValue,
  }),
  commonCalendarData: (upcomingCalendarData) => ({
    type: actions.UPCOMING_CALENDAR_DATA,
    upcomingCalendarData,
  }),
};
export default actions;
