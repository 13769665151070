import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { compilerSection } from "../../config";
import DashboardAction from "../../redux/dashboard/actions";
import Header from "./header";
import Footer from "./footer";
import axios from "axios";
import SkeltonLoader from "../Page/SkeltonLoder";

class StudentDashboard extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      isSectionVisible: false,
      sectionId: null,
      isDataLoaded: false,
      sectionCompilerLists: "",
      langauge: "",
      lock_level_two_text: "",
      lock_level_three_text: "",
      
    };
  }

  componentDidMount() {
    var token = localStorage.getItem("u$tOken");
    var milliseconds = new Date().getTime();
    var getCourse = localStorage.getItem("course");

    if (getCourse) {
      axios
        .get(
          `${compilerSection.compilerSections}?courseId=${getCourse}&c=${milliseconds}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          this.setState({
            sectionCompilerLists: res.data.data,
            langauge: getCourse,
          });
        });
    } else {
      axios
        .get(`${compilerSection.compilerSections}?c=${milliseconds}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          this.setState({
            sectionCompilerLists: res.data.data,
            langauge: getCourse,
          });
        });
    }
  }
  hasClass(elem, className) {
    return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
  }

  mouseOver = (indexValue) => {
    let divId = indexValue;
    const element = document.getElementById("containerId_" + divId);
    let className = 'lock-screen';
    //&&  document.getElementsByClassName("userLockMsg") != null
    if (new RegExp(' ' + className + ' ').test(' ' + element.className + ' ') == true 
    )
    {
      if (document.getElementById("userLockMsg1") != null) {
        document.getElementById("userLockMsg1").innerHTML = "Complete  Assessment Level 1 to unlock Level 2";
      }
      if (document.getElementById("userLockMsg2") != null) {
        document.getElementById("userLockMsg2").innerHTML = "Complete  Assessment Level 2 to unlock Level 3";
      }

    }
      
   
  }
  handleSectionDetails = (list, course_id, unitData, index) => {
    var milliseconds = new Date().getTime();
    localStorage.setItem("course_id", course_id);
    this.props.history.push({
      pathname: `/unit-details/c=${milliseconds}`,
      state: { list: list, unitData: unitData },
    });
  };

  handleVisible = (id, containerId = '') => {
    this.setState({
      sectionId: id,
      isSectionVisible: !this.state.isSectionVisible,
    });
  };

  handleChangeOption = (event) => {
    localStorage.setItem("course", event.target.value);
    var sectionListData =
      this.state.sectionCompilerLists &&
      this.state.sectionCompilerLists.courseList;
    var coursename = sectionListData.filter(
      (ele) => ele.courseId == event.target.value && ele.course_title
    );
    localStorage.setItem(
      "coursename",
      JSON.stringify(coursename[0].course_title)
    );
    var token = localStorage.getItem("u$tOken");
    var milliseconds = new Date().getTime();
    this.setState({ langauge: event.target.value }, () => {
      axios
        .get(
          `${compilerSection.compilerSections}?courseId=${this.state.langauge}&c=${milliseconds}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          this.setState({ sectionCompilerLists: res.data.data });
        });
    });
  };


  handlePopupClick = () => {
   alert('true');
  }
  checkPopupText = (itemTitle, key) => {
    
 
    
  }

  render() {
    const { sectionCompilerLists } = this.state;

    const sectionLists = sectionCompilerLists && sectionCompilerLists.sections;
    const courseList = sectionCompilerLists && sectionCompilerLists.courseList;

   

    const horizontalBarPercentage = (percetageNow) => {
      if (percetageNow > 0 && percetageNow <= 25) {
        return 25;
      } else if (percetageNow > 25 && percetageNow <= 75) {
        return 75;
      } else if (percetageNow > 75) {
        return 100;
      }
    };

    let titleIs = "";
    const renderCheck = (title) => {
      if (titleIs != title) {
        return (titleIs = title);
      } else {
        return false;
      }
    };

    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <section className="content container-fluid">
            <div className="main-sec">
              <div className="language_dropdown">
                <label className="profile-top-label">Select Course</label>
                <div className="select-arrow">
                  <select
                    className="course"
                    name="course"
                    onChange={this.handleChangeOption}
                    value={this.state.langauge}
                  >
                    {Array.isArray(courseList) &&
                      courseList.map((ele, index) => (
                        <React.Fragment key={index}>
                          <option value={ele.courseId} id={ele.course_title}>
                            {ele.course_title}
                          </option>
                        </React.Fragment>
                      ))}
                  </select>
                  <span className="arrow-down">
                    <i className="fas fa-chevron-down"></i>
                  </span>
                </div>
              </div>
              <div className="level-box">
                <div className="row">
                  {Array.isArray(sectionLists) ? (
                    sectionLists.map((ele, index) => (
                      
                      <div className="col-lg-4 col-md-4 md-gap" id={index} key={ele.id}>
                        <div
                          className={
                            ele.color == null ? "level-1 color-1" : "level-1"
                          }
                          style={{
                            backgroundColor: ele.color != null && ele.color,
                          }}
                        >
                          <div className="level-image">
                            <img
                              src={
                                ele.image != null
                                  ? ele.image
                                  : "/dist/img/level-1.png"
                              }
                              className="img-fluid"
                              alt="Logo"
                            />
                          </div>
                          <div className="level-content">
                            <h2>{ele.section_title} </h2>
                          </div>
                        </div>
                        <div 
                          onMouseEnter={() => this.mouseOver(index)}
                          onMouseLeave={() => this.mouseLeave}
                          className={!ele.status ? "lock-screen" : undefined}
                          id={`containerId_`+index}
                        >
                          <div className="level-description">
                            <button
                              className="btn-start"
                              onClick={() => this.handleVisible(ele.id, index)}
                              id={`head_` + index}
                            >
                              Start Learning
                              <span
                                className={
                                  this.state.sectionId == ele.id &&
                                  this.state.isSectionVisible
                                    ? ""
                                    : "uparrow"
                                }
                                id={`headarrow_` + index}
                              >
                                <i className="fas fa-chevron-down"></i>
                              </span>
                            </button>

                            <div id={index+1}>
                              
                              <ul>
                                {
                                  ele.section_units.map((item, idx) =>
                                    item.show_assessment == 1 ? (
                                     
                                      <>
                                      
								
                                        <div
                                          className="level-button"
                                          style={{
                                            display:
                                              renderCheck(ele.section_title) ==
                                                titleIs
                                                ? "block"
                                                : "none",
                                          }}
                                          
                                        >
                                          
                                          <button className="btn-start">
                                            Assessment
                                          <span>
                                              <img
                                                src="../dist/img/book-icon.png"
                                                alt=""
                                              />
                                            </span>
                                          </button>
                                        </div>
                                        <div
                                          className="show">
                                          <li
                                            key={item.id}
                                            className={
                                              item.percentage == 100
                                                ? "selected"
                                                : ""
                                            }
                                            onClick={() =>
                                              this.handleSectionDetails(
                                                item,
                                                ele.course_id,
                                                ele,
                                                idx
                                              )
                                            }
                                          >
                                            <span className="itemName">
                                              {item.title}
                                              
                                            </span>
                                            <div className="processBar1">
                                              <span className="progress-count">
                                                {item.percentage
                                                  ? `${Math.round(
                                                    item.percentage
                                                  )} %`
                                                  : null}
                                              </span>
                                              <div className="progress">
                                                <div
                                                  className={`progress-bar progress${horizontalBarPercentage(
                                                    item.percentage
                                                  )}`}
                                                  style={{
                                                    width: `${item.percentage}%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </li>
                                        </div>
                                      </>
                                    ) : (
                                      
                                        
                                    <div className={
                                        this.state.sectionId == ele.id &&
                                            this.state.isSectionVisible 
                                            ? (item.title != 'Project Assignment Level 1' &&
                                              item.title != 'Project Assignment Level 2' &&
                                              item.title != 'Project Assignment Level 3') ? "hide" : "show"
                                          : "show"
                                      }
                                    >
                                      <li
                                        key={item.id}
                                        className={
                                          item.percentage == 100
                                            ? "selected"
                                            : ""
                                        }
                                        onClick={() =>
                                          this.handleSectionDetails(
                                            item,
                                            ele.course_id,
                                            ele,
                                            idx
                                          )
                                        }
                                      >
                                            <span className="itemName">
                                              
                                              {item.title} {this.checkPopupText(item.title, index)}
                                            </span>
                                        <div className="processBar1">
                                          <span className="progress-count">
                                            {item.percentage
                                              ? `${Math.round(
                                                  item.percentage
                                                )} %`
                                              : null}
                                          </span>
                                          <div className="progress">
                                            <div
                                              className={`progress-bar progress${horizontalBarPercentage(
                                                item.percentage
                                              )}`}
                                              style={{
                                                width: `${item.percentage}%`,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </li>
                                    </div>
                                  )
                                )}
                              </ul>
                              
                            </div>
                          </div>
                          <div className="lockscreen-content" id={`userLockMsg` + index}>


                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <SkeltonLoader />
                  )}
                </div>
               
              </div>
              <div className="bottom-button">
                <button className="btn-credit" type="button" data-toggle="modal" data-target="#handlePopupClick"><span><i className="far fa-images"></i></span>Photo Credits</button>
              </div>

              <div className="modal" id="handlePopupClick">
                <div className="modal-dialog modal-photopath modal-lg">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button type="button" className="close" data-dismiss="modal">&times;</button>
                      <h2><span><i className="far fa-images"></i></span>Photo Credits List</h2>
                      <div className="list-of-photo scrollBarList">
                        <ul>
                          <li>cash1994.shutterstock.com</li>
                          <li>John Foxx Collection. Imagestate</li>
                          <li>MedusArt. Shutterstock</li>
                          <li>Dbrinda. Shutterstock</li>
                          <li>Coleman Yuen. Pearson Education Asia Ltd</li>
                          <li>Yayayoyo. Shutterstock</li>
                          <li>Occeii. Shutterstock</li>
                          <li>Screenshot from Tap Dancing Song, Cocomelon - Nursery Rhymes You Tube Channel available on youtube.com, accessed on 26th Feb. 2021 </li>
                          <li>©2021 Google LLC, used with permission. Google and the Google logo are registered trademarks of Google LLC </li>
                          <li>Joyce Vincent. Shutterstock </li>
                          <li>Microsoft and/or its respective suppliers make no representations about the suitability of the information contained in the documents and related graphics published as part of the services for any purpose. All such documents and related graphics are provided “as is” without warranty of any kind. Microsoft and/or its respective suppliers hereby disclaim all warranties and conditions with regard to this information, including all warranties and conditions of merchantability, whether express, implied or statutory, fitness for a particular purpose, title and non-infringement. In no event shall Microsoft and/or its respective suppliers be liable for any special, indirect or consequential damages or any damages whatsoever resulting from the loss of use, data or profits, whether in an action of contract, negligence or other tortious action, arising out of or in connection with the use or performance of information available from the services. The documents and related graphics contained herein could include technical inaccuracies or typographical errors. Changes are periodically added to the information herein. Microsoft and/or its respective suppliers may make improvements and/or changes in the product(s) and/or program(s) described herein at any time. Partial screen shots may be viewed in full within the software version specified. </li>
                          <li>hadot 760. Shutterstock </li>
                          <li>Gorodenkoff. Shutterstock </li>
                          <li>Computer Magic.Microsift. CC BY 3.0.www.soundbible.com </li>
                          <li>Chinch. Shutterstock </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}



export default connect(
  (state) => ({
    state,
    isTokenResponse: state.Auth.idToken,
    sectionCompilerList: state.dash.sectionListData,
  }),
  { ...DashboardAction }
)(StudentDashboard);
