import { Map } from "immutable";
import actions from "./actions";
/**
 * Things you should never do inside a reducer:
1. Mutate its arguments;
2. Perform side effects like API calls and routing transitions;
3. Call non-pure functions, e.g. Date.now() or Math.random().
 */

const initState = new Map({
  success: false,
  msg: "",
  result: [],
});

export default function courseReducer(state = initState, action) {
  switch (action.type) {
    case actions.COURSE_DETAIL_SUCCESS:
      return state
        .set("result", action.payload.result)
        .set("success", action.success);

    case actions.CONTENT_DETAIL_SUCCESS:
      return state
        .set("result", action.payload.result)
        .set("success", action.success);

    default:
      return state;
  }
  return state;
}
