import React from "react";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import createHistory from "history/createBrowserHistory";

import { siteUrl } from "./siteConfig";
import Home from "../src/containers/Page/home";
import Login from "../src/containers/Page/Login";
import ErrorPage from "../src/containers/Page/ErrorPage";
import ComingSoon from "../src/containers/Page/ComingSoon";
import Profile from "../src/containers/Page/Profile";
import QuizViewNew from "../src/containers/Page/QuizViewNew";
import Registration from "../src/containers/Page/Registration";
import ForgotPassword from "../src/containers/Page/ForgotPassword";
import Onlinecomplier from "../src/containers/Page/onlinecomplier";
import ResetPassword from "../src/containers/Page/ResetPassword";
const history = createHistory({ basename: siteUrl.base_dir });

const PublicRoutes = ({ isLoggedIn, props }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route exact path={"/dashboard/:dateTime"} component={Home} />

        <Route exact path={"/loginerror"} component={ErrorPage} />
        <Route exact path={"/tutorial"} component={ComingSoon} />
        <Route
          exact
          path={"/reset-password/:token"}
          component={ResetPassword}
        />
        <Route exact path={"/myProfile"} component={Profile} />
        <Route exact path={"/quiz-start"} component={QuizViewNew} />
        <Route exact path={"/"} component={Login} />
        <Route exact path={"/signup"} component={Registration} />
        <Route exact path={"/forgot_password"} component={ForgotPassword} />
        <Route
          exact
          path={"/unit-details/:dateTime"}
          component={Onlinecomplier}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
