import actions from "./actions";
/**
 * Things you should never do inside a reducer:
1. Mutate its arguments;
2. Perform side effects like API calls and routing transitions;
3. Call non-pure functions, e.g. Date.now() or Math.random().
 */

const initState = {
  success: false,
  msg: "",
  result: [],
  sectionListData: [],
};

export default function dashReducer(state = initState, action) {
  switch (action.type) {
    case actions.USER_COURSES_SUCCESS:
      return {
        ...state,
        result: action.payload.result,
        success: action.success,
      };

    case actions.INSTRUCTOR_COURSES_SUCCESS:
      return {
        ...state,
        result: action.payload.result,
        success: action.success,
      };
    case actions.SECTION_COMPILER_LIST_SUCCESS:
      return {
        ...state,
        sectionListData: action.payload.result,
        success: true,
      };
    default:
      return state;
  }
}
