import { user, header } from "../config";

class UserApi {
  updateUserProfile = async (userData) => {
    var milliseconds = new Date().getTime();
    let userUpdateUrl = `${user.userProfileUpdate}?c=${milliseconds}`;
    var token = localStorage.getItem("u$tOken");
    return await fetch(userUpdateUrl, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getUserProfile = async (userData) => {
    var milliseconds = new Date().getTime();
    let getUserData = `${user.userProfileGetAPIUrl}?c=${milliseconds}`;
    var token = localStorage.getItem("u$tOken");
    return await fetch(getUserData, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getUserNotification = async () => {
    let getUserNotification = user.getUserNotification;
    const user_id = localStorage.getItem("instructorid").replace(/['"]+/g, "");

    return await fetch(`${getUserNotification}?user_id=${user_id}`, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getUserSeminar = async () => {
    let getSeminar = user.getUserSeminar;

    return await fetch(getSeminar, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getProfessionList = async () => {
    var milliseconds = new Date().getTime();
    var token = localStorage.getItem("u$tOken");
    let getProfessionList = `${user.fetchProfession}?c=${milliseconds}`;

    return await fetch(getProfessionList, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  getMobileOtpApi = async () => {
    let sendOtp = user.sendOtp;

    return await fetch(sendOtp, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };
}
export default new UserApi();
