import React from "react";
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
// import { ThemeProvider } from "styled-components";

import { IntlProvider } from "react-intl";
//import themes from "./settings/themes";

//import { themeConfig } from "./settings";
// import DashAppHolder from "./dashAppStyle";
import Boot from "./redux/boot";

// const user_token = localStorage.getItem('u$tOken');
//
// if(user_token == null){
//     alert('Please Login First');
// }
//baaarea

const DashApp = () => (
  <IntlProvider>
    <Provider store={store}>
      <PublicRoutes history={history} />
    </Provider>
  </IntlProvider>
);
Boot()
  .then(() => DashApp())
  .catch((error) => console.error(error));

export default DashApp;
