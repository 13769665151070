import { Map } from "immutable";
import actions from "./actions";
/**
 * Things you should never do inside a reducer:
1. Mutate its arguments;
2. Perform side effects like API calls and routing transitions;
3. Call non-pure functions, e.g. Date.now() or Math.random().
 */

const initState = new Map({
  success: false,
  msg: "",
  isbatchSuccess: false,
  result: [],
  unitList: [],
  batches: [],
  hostid: [],
  quizList: [],
  addSection: [],
  notification: [],
  assigmentData: [],
  verifyAssigmentData: [],
  instructorComment: [],
  attendanceTableData: [],
  mySchedule: [],
  quizDetails: [],
  quizQuestion: [],
  studentListDetails: [],
  AssementScore: [],
  verifyQuiz: [],
  QuestionList: [],
  InstructorQuizList: [],
  languageArrayList: [],
});

export default function instructorDetailReducer(state = initState, action) {
  switch (action.type) {
    case actions.INSTRUCTOR_UNIT_TYPE_SUCCESS:
      return state
        .set("result", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_UNIT_LIST_SUCCESS:
      return state.set("unitList", action.payload.result);

    case actions.INSTRUCTOR_BATCHES_SUCCESS:
      return state
        .set("batches", action.payload.result)
        .set("isbatchSuccess", true);

    case actions.INSTRUCTOR_HOST_ID_SUCCESS:
      return state.set("hostid", action.payload.result);

    case actions.INSTRUCTOR_QUIZ_LIST_SUCCESS:
      return state.set("quizList", action.payload.result);

    case actions.INSTRUCTOR_ADD_SECTION_SUCCESS:
      return state.set("addSection", action.payload.result);

    case actions.INSTRUCTOR_STUDENT_NOTIFICATION_SUCCESS:
      return state.set("notification", action.payload.result);

    case actions.INSTRUCTOR_ASSIGNMENT_DETAIL_SUCCESS:
      return state.set("assigmentData", action.payload.result);

    case actions.INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL_SUCCESS:
      return state.set("viewAssigmentData", action.payload.result);

    case actions.INSTRUCTOR_VERIFY_ASSIGNMENT_SUCCESS:
      return state.set("verifyAssigmentData", action.payload.result);

    case actions.INSTRUCTOR_ASSIGNMENT_COMMENT_SUCCESS:
      return state.set("instructorComment", action.payload.result);

    case actions.INSTRUCTOR_ATTENDANCE_LIST_SUCCESS:
      return state
        .set("attendanceTableData", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_MYSCHEDULE_SUCCESS:
      return state
        .set("mySchedule", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_QUIZ_DETAILS_SUCCESS:
      return state
        .set("quizDetails", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_QUIZ_QUESTION_SUCCESS:
      return state
        .set("quizQuestion", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_STUDENT_LIST_QUIZ_SUCCESS:
      return state
        .set("studentListDetails", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_STUDENT_ASSESMENT_SUCCESS:
      return state
        .set("AssementScore", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_VERIFY_QUIZ_SUCCESS:
      return state
        .set("verifyQuiz", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_QUIZ_QUESTION_LIST_SUCCESS:
      return state
        .set("QuestionList", action.payload.result)
        .set("success", action.success);

    case actions.INSTRUCTOR_GET_QUIZ_SUCCESS:
      return state
        .set("InstructorQuizList", action.payload.result)
        .set("success", action.success);
    case actions.LANGUAGE_LIST_SUCCESS:
      return state
        .set("languageArrayList", action.payload.result)
        .set("success", action.success);
    default:
      return state;
  }
  return state;
}
