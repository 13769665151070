import jwtDecode from "jwt-decode";
// import SuperFetch from "./superFetch";
import { userLogin, header } from "../config";
import axios from "axios";

class AuthHelper {
  login = async (userInfo) => {
    let loginUrl = userLogin.login;
    if (!userInfo.email || !userInfo.password) {
      return { error: "please fill in the input" };
    }
    return await axios
      .post(loginUrl, userInfo, {
        headers: header,
      })
      .then((response) => {
        if (response.data.status_code == 404) {
          return response;
          // localStorage.setItem("message", response.data.message);
        } else {
          localStorage.setItem(
            "u$tOken",
            "Bearer " + response.data.access_token
          );
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("role_name", response.data.user[0].role_name);
          localStorage.setItem("u$eRCode", response.data.user[0].user_code);
          localStorage.setItem("emailId", response.data.user[0].email);
          localStorage.setItem("userid", response.data.user[0].id);
          localStorage.setItem("name", response.data.user[0].name);
          return this.checkExpirity(response.data.access_token);
        }
      });
  };

  postSignUpData = async (data) => {
    let registerUrl = userLogin.compilerUserRegistration;
    return await axios
      .post(
        registerUrl,
        {
          ...data["data"],
        },
        {
          headers: header,
        }
      )
      // .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  postresetpassword = async (emailId) => {
    const token = localStorage.getItem("resettoken");
    let resetUrl = `${userLogin.resetPassword}/${token}`;

    return await axios
      .put(
        resetUrl,
        {
          ...emailId["emailId"],
        },
        {
          headers: header,
        }
      )
      // .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  postForgotpassword = async (emailId) => {
    let forgotUrl = userLogin.forgotPassword;
    return await axios
      .post(
        forgotUrl,
        {
          ...emailId["emailId"],
        },
        {
          headers: header,
        }
      )
      // .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => ({ error }));
  };

  async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: "Token expired" };
    }
    return await axios
      .get("secret/test", { token })
      .then((response) => ({
        status: "200",
        message: "Success",
      }))
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  checkExpirity = (token) => {
    if (!token) {
      return {
        error: "not matched",
      };
    }
    try {
      const profile = jwtDecode(token);

      const expiredAt = profile.expiredAt || profile.exp * 1000;

      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: "Token expired" };
      }
    } catch (e) {
      console.log(e);

      return { error: "Server Error" };
    }
  };
}
export default new AuthHelper();
