import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken } from "../../helpers/utility";
import actions from "./actions";
import AuthApi from "../../helpers/authHelper";

const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  
  yield takeEvery(actions.LOGIN_REQUEST, function*({ payload }) {
    try {
      const result = yield call(AuthApi.login, payload.userData);
      if (result.token) {
        yield put(actions.loginSuccess(result.token));
      } else {
       
        yield put(actions.loginSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* singupRequest() {
  yield takeEvery(actions.SIGNUP, function*({ payload }) {
    try {
      const result = yield call(AuthApi.postSignUpData, payload);

      if (result.status == 200) {
        yield put(actions.signUpSuccess(result.data));
      } else {
        yield put(actions.signUpSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* forgotpasswordRequest() {
  yield takeEvery(actions.FORGOT_PASSWORD, function*({ payload }) {
    try {
      const result = yield call(AuthApi.postForgotpassword, payload);

      if (result.status == 200) {
        yield put(actions.forgotpasswordsuccess(result.data));
      } else {
        yield put(actions.forgotpasswordsuccess(result.error));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* resetPasswordRequest() {
  yield takeEvery(actions.RESET_PASSWORD, function*({ payload }) {
    try {
      const result = yield call(AuthApi.postresetpassword, payload);

      if (result.status == 200) {
        yield put(actions.resetPasswordSuccess(result.data));
      } else {
        yield put(actions.resetPasswordSuccess(result.error));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

// export function* loginRequest() {
//   yield takeEvery("LOGIN_REQUEST", function*() {
//     if (fakeApiCall) {
//       yield put({
//         type: actions.LOGIN_SUCCESS,
//         token: "secret token",
//         profile: "Profile",
//       });
//     } else {
//       yield put({ type: actions.LOGIN_ERROR });
//     }
//   });
// }

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*() // payload
  {
    yield localStorage.setItem(
      "id_token",
      localStorage.getItem("token")
      // payload.token
    );
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push("/"));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get("idToken");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: "Profile",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(singupRequest),
    fork(forgotpasswordRequest),
    fork(resetPasswordRequest),
  ]);
}
