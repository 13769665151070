import React, { Component } from "react";
import { connect } from "react-redux";
import PDFReader from "./PdfReader";
import QuizView from "./QuizView";
import { header, course } from "../../config";

class DataPreview extends Component {
  constructor(props) {
    super(props);
    this.state = { Quiz: {} };
    this.completeEntity = this.completeEntity.bind(this);
    this.completeQuiz = this.completeQuiz.bind(this);
  }

  completeEntity(e) {
    let entityId = e.currentTarget.dataset.entityid;
    let batchId = e.currentTarget.dataset.batchid;

    // let nextsec = e.currentTarget.dataset.nextsec;
    let coureId = this.props.courseId;
    let data = {
      entity_id: entityId,
      course_id: coureId,
      batch_id: batchId,
    };
    //document.getElementById("finish_btn").disabled = true;
    const entityCompleteUrl = course.entityComplete;
    fetch(entityCompleteUrl, {
      method: "post",
      headers: header,
      body: JSON.stringify(data),
    })
      .then(function(response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })

      .then((res) => {
        if (res.data) {
          //document.getElementById("finish_btn").disabled = false;
          // let xyz = this.props.nextUnit.section_id
          if (this.props.nextUnit !== undefined) {
            localStorage.setItem(
              "searchSectionId",
              this.props.nextUnit.section_id
            );
            localStorage.setItem(
              "searchElementId",
              this.props.nextUnit.entity_id
            );
            this.props.loadContentFilter();
          }
        } else {
        }
      });
  }

  completeQuiz(entityid, courseid) {
    let entityId = entityid;
    let courseId = courseid;
    // let nextsec = e.currentTarget.dataset.nextsec;

    let data = {
      entity_id: entityId,
      course_id: courseId,
    };

    const entityCompleteUrl = course.entityComplete;
    fetch(entityCompleteUrl, {
      method: "post",
      headers: header,
      body: JSON.stringify(data),
    })
      .then(function(response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })

      .then((res) => {
        if (res.data) {
          // let xyz = this.props.nextUnit.section_id;
          if (this.props.nextUnit && this.props.nextUnit.section_id) {
            localStorage.setItem(
              "searchSectionId",
              this.props.nextUnit.section_id
            );
            localStorage.setItem(
              "searchElementId",
              this.props.nextUnit.entity_id
            );
            this.props.loadContentFilter();
          }
        } else {
        }
      });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    const filteredData = this.props.filteredData;

    const nextUnit = this.props.nextUnit;
    let quizDetail = "",
      unitDetail = "";
    if (typeof filteredData === "object") {
      if (filteredData.type == 9) {
        unitDetail = filteredData;
      }

      if (filteredData.type == 5) {
        unitDetail = filteredData;
      }

      if (filteredData.type == 7) {
        unitDetail = filteredData;
      }

      if (filteredData.type == 2) {
        unitDetail = filteredData;
      }

      if (filteredData.type == 6) {
        unitDetail = filteredData;
      }
      if (filteredData.type == 8) {
        this.props.history.push("/studentassignment");
      }
    }

    return (
      <div>
        {(() => {
          if (filteredData.type == 9 && filteredData.quiz_detail != []) {
            if (
              filteredData.quiz_detail[0].quiz_type == "Practice" &&
              filteredData.quiz_detail[0].type == 2
            ) {
              return (
                <QuizView
                  filteredData={filteredData}
                  quizRetake={
                    filteredData.quiz_detail[0] &&
                    filteredData.quiz_detail[0].number_of_extra_retake
                  }
                  quizId={filteredData.quiz_meeting_id}
                  completeEntity={this.completeQuiz}
                />
              );
            } else if (
              filteredData.quiz_detail[0].quiz_status == "Not start" &&
              filteredData.quiz_detail[0].expired_status == "Not Expired"
            ) {
              return (
                <div className="col-lg-12 notStarted-alert">
                  <p>
                    Quiz Is Not Started Yet Start At{" "}
                    {filteredData.quiz_detail[0].start_date_time}
                  </p>
                </div>
              );
            } else if (
              filteredData.quiz_detail[0].quiz_status == "Start" &&
              filteredData.quiz_detail[0].expired_status == "Not Expired"
            ) {
              return (
                <QuizView
                  filteredData={filteredData}
                  quizRetake={
                    filteredData.quiz_detail[0] &&
                    filteredData.quiz_detail[0].number_of_extra_retake
                  }
                  quizId={filteredData.quiz_meeting_id}
                  completeEntity={this.completeQuiz}
                />
              );
            } else if (
              filteredData.quiz_detail[0].quiz_status == "Start" &&
              filteredData.quiz_detail[0].expired_status == "Expired"
            ) {
              return (
                <div className="col-lg-12 notStarted-alert">
                  <p>
                    Quiz Has Been Expired. End Date Is{" "}
                    {filteredData.quiz_detail[0].end_date_time}
                  </p>
                </div>
              );
            }
          } else if (filteredData.type == 9 && filteredData.quiz_detail == []) {
            return (
              <div className="col-lg-12 notStarted-alert">
                Quiz Details Is Not Available
              </div>
            );
          } else if (filteredData.type == 2) {
            return (
              <div>
                {/* <button
                  id={"finish_btn"}
                  className={"btn btn-primary finish_btn"}
                  data-nextsec={"abhishek"}
                  data-entityid={filteredData.id}
                  onClick={this.completeEntity}
                >
                  Finish
                </button> */}
                {/*<a className={'btn btn-primary'} href={`${filteredData.recording_detail.recording_url}`} target={'_blank'}>Recording Url</a>*/}
                <video className={"video_iframe"} height="500" controls>
                  <source
                    src={filteredData.url}
                    type="video/mp4"
                    className="source_src"
                  />
                </video>
              </div>
            );
          } else if (filteredData.type == 7) {
            var result =
              filteredData.url !== null &&
              filteredData.url
                .split("")
                .splice(8, 7)
                .join("");
            if (filteredData.type == 7 && result == "zoom.us") {
              return (
                <>
                  <div id="test">&nbsp;</div>
                  <div>
                    <iframe
                      allowFullScreen
                      frameborder="0"
                      width="815"
                      height="600"
                      src={filteredData.url + "#toolbar=0"}
                    ></iframe>
                  </div>
                </>
              );
            } else {
              return (
                <div>
                  <video className={"video_iframe"} height="500" controls>
                    <source
                      src={filteredData.url}
                      type="video/mp4"
                      className="source_src"
                    />
                  </video>
                </div>
              );
            }
          } else if (filteredData.type == 10) {
            return (
              <div>
                <iframe
                  width="830"
                  height="400"
                  src={filteredData.url}
                ></iframe>
              </div>
            );
          } else if (filteredData.type == 5) {
            return (
              <PDFReader UnitDetail={unitDetail} entityId={filteredData.id}
                batchId={filteredData.batch_id}
                entityProgress={filteredData.unit_progress}
                onClick={this.completeEntity}
             />
            );
          } else
            return (
              // <div className="col-lg-12 quiz_accessable">
              //   <h2>Invalid Data</h2>
              // </div>
              <div className={"loader_box"}>
                {/* <h1>sdds</h1> */}
                <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
              </div>
            );
          // <embed src="https://images.qversity.com/uploads/2019/01/Truth-Confidentiality-Case-Studies.pdf" type="application/pdf" width="100%" height="600px" />
        })()}
      </div>
    );
  }
}

export default connect()(DataPreview);
