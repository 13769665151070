import React, { Component } from "react";

import Header from "./header";
import { Link } from "react-router-dom";
import Footer from "./footer";
import PdfReaderNew from "./PdfReaderNew";
import DataPreview from "../Page/DataPreview";
import CompilerQuestion from "../Page/CompilerQuestion";

import $ from "jquery";

class onlinecomplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: "",
      unitList: "",
      count: 0,
      script: "",
      compilerData: "",
      questionNo: "",
      compilerUnitId: "",
      isLoaderVisible: "none",
      isNextVisible: true,
      htmlsrc: "",
      IsdefaultValue: "",
    };
  }

  componentWillMount() {
    this.setState({ isLoaderVisible: "block" });
    if (this.props.history.location && this.props.history.location.state) {
      const params = this.props.history.location.state.list;

      const unitDataList = this.props.history.location.state.unitData;

      var getUnit = Object.keys(unitDataList)
        .map((key) => Array.isArray(unitDataList[key]) && unitDataList[key])
        .filter(Boolean);
      var combineArray = Array.prototype.concat.apply([], getUnit);

      var getUnitPostion = combineArray
        .map((item, index) => item.id == params.id && index)
        .filter((ele) => ele != false);
      if (getUnitPostion.length == 0) {
        this.setState({ count: 0 });
      } else {
        this.setState({ count: getUnitPostion[0] });
      }

      this.setState({
        params,
        unitList: combineArray,
        isLoaderVisible: "none",
      });
    }
  }
  componentDidMount() {
    const { count, unitList } = this.state;

    this.setState({
      isNextVisible: count == unitList.length - 1 ? false : true,
    });
  }
  handleNextUnit = () => {
    const { count, unitList } = this.state;

    if (count < unitList.length - 1) {
      this.setState((state) => ({
        params: unitList[state.count + 1],
        count: state.count + 1,
        isNextVisible: count == unitList.length - 2 ? false : true,
      }));
    }
  };

  handleprevUnit = () => {
    const { count, unitList } = this.state;

    if (count > 0 && count < unitList.length) {
      this.setState((state) => ({
        params: unitList[state.count - 1],
        count: state.count - 1,
        isNextVisible: true,
      }));
    }
  };

  handleChangeScript = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleIframeSrc = (data) => {
    this.setState({ htmlsrc: data.data });
  };

  checkDefaultProgram = (checkDefaultValue) => {
    this.setState({
      IsdefaultValue: checkDefaultValue.defaultValue,
    });
  };

  render() {
    const { params, compilerUnitId, isLoaderVisible } = this.state;

    var milliseconds = new Date().getTime();
    var courseName = localStorage.getItem("coursename");

    const renderData = () => {
      if (params && params.type == 5) {
        return (
          <div className="col-lg-10 col-md-10 offset-lg-1">
            <PdfReaderNew url={params} />
          </div>
        );
      } else if (params && params.type == 1) {
        return <CompilerQuestion data={params} />;
      } else {
        return (
          <div className="col-lg-8 col-md-7 offset-lg-2 quizSection">
            <div className="compiler-details">
              <h2>{params.title}</h2>
              <div className="que-box">
                <DataPreview filteredData={params} courseId={params.id} />
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <section className="content container-fluid">
            <div className="backButton">
              <Link to={`/dashboard/c=${milliseconds}`}>
                <span>
                  <i className="fas fa-home"></i>
                </span>
                Home
              </Link>
            </div>
            {/* modal loader */}
            <div className="modal" style={{ display: isLoaderVisible }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-body loaderBox"
                    style={{ margin: "auto" }}
                  >
                    <h3>Please wait...</h3>
                    <div className="loader"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* loader ends */}
            {/* sectoin Starts */}
            <div className={params.type == 9 ? "main-sec" : "main-sec1"}>
              <div className="row">
                {/* <div 
         className={ 
                   params.type == 9 || params.type == 5
                      ? "col-lg-8 col-md-7 offset-lg-2 quizSection"
                      : 
                  }
                 > */}
                {renderData()}
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ul className="pagination-box">
                  <li>
                    {this.state.count != 0 && (
                      <div
                        className="custom-link"
                        onClick={this.handleprevUnit}
                      >
                        <span>
                          <i className="fas fa-chevron-left"></i>
                        </span>
                        Previous
                      </div>
                    )}
                  </li>

                  <li>
                    {this.state.isNextVisible && (
                      <div
                        className="custom-link"
                        onClick={this.handleNextUnit}
                      >
                        Next
                        <span>
                          <i className="fas fa-chevron-right"></i>
                        </span>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default onlinecomplier;
