import Auth from "./auth/reducer";
import App from "./app/reducer";
import dash from "./dashboard/reducer";
import user from "./user/reducer";
import course from "./course/reducer";
import Instructor from "./instructorDetails/reducer";
export default {
  Auth,
  App,
  dash,
  user,
  course,
  Instructor,
};
