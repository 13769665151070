import React, { Component } from "react";

class ErrorPage extends Component {
  render() {
    return (
      <div>
        <div className="error_page error-404" id="notfound">
          <div className="row">
            <div className="col-lg-6">
              <div className="error-content">
                <h1>404</h1>
                <h2>Oops!</h2>
                <p>THE PAGE CAN’T BE FOUND</p>
                <button>Go Home</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
