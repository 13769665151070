import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import UserNotification from "../../redux/user/actions";
import Sidebar from "./SidebarMenu";
import { siteUrl } from "../../siteConfig";
import DashboardAction from "../../redux/dashboard/actions";
import $ from "jquery";

class Header extends Component {
  state = {
    redirectToReferrer: false,
  };

  signout = () => {
    localStorage.removeItem("u$tOken");
    localStorage.removeItem("u$eRInf");
    localStorage.clear();
    this.props.history.push("/login");
  };

  toggelLeftMenu = () => {
    $(".profile_detail_box").slideToggle();
  };

  render() {
    const { unitTitle } = this.props;

    const user_name = localStorage.getItem("name");

    var u$tOken = localStorage.getItem("u$tOken");
    var milliseconds = new Date().getTime();
    return (
      <div>
        {(() => {
          if (u$tOken !== null) {
            return (
              <div>
                <header className="main-header">
                  <Link to={`/dashboard/c=${milliseconds}`} className="logo">
                    <img src="/dist/img/logo.png" alt="home" />
                  </Link>

                  <nav
                    className="navbar navbar-static-top"
                    role="navigation"
                    style={{ marginLeft: "0px !important" }}
                  >
                    <a
                      href="/#"
                      className="sidebar-toggle"
                      data-toggle="push-menu"
                      role="button"
                    ></a>

                    <h3 style={{ marginLeft: "45px", lineHeight: "58px" }}>
                      {unitTitle}
                    </h3>
                    {/* user detsil left sec */}
                    <a
                      href="/#"
                      className=" user_name_detail"
                      data-toggle="dropdown"
                    >
                      {/*<img src="/images/dashbord_img/LMS-08.png" className="img-fluid" alt="user icon"/>
                                     <h3>Hello! </h3>*/}
                    </a>
                    <ul className="dropdown-menu drop_box2">
                      <li>
                        <h4>Course:</h4>
                        <p>Mechnical Engineeering in CS </p>
                      </li>
                      <li>
                        <h4>Stream:</h4>
                        <p>B.Tech. </p>
                      </li>
                      <li>
                        <h4>Semester:</h4>
                        <p> &#x2162; </p>
                      </li>
                    </ul>
                    {/* end left */}

                    <div className="navbar-custom-menu">
                      <ul className="nav navbar-nav all_notification">
                        {/*-- User Account Menu -->*/}
                        <li className="dropdown user user-menu">
                          <a
                            href="/#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {/*-- The user image in the navbar-->*/}
                            <i className="far fa-user"></i>
                            {/*-- hidden-xs hides the username on small devices so only the image appears. -->*/}
                          </a>

                          <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">
                            <div className="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
                              <div className="kt-user-card__avatar">
                                <img
                                  src="/dist/img/user_img.jpg"
                                  className="profile-image"
                                  alt="profile-image"
                                />
                              </div>
                              <div className="kt-user-card__name">
                                {user_name}
                              </div>
                            </div>

                            <div className="kt-notification">
                              <Link
                                to="/myprofile"
                                className="kt-notification__item"
                              >
                                <div className="kt-notification__item-icon">
                                  <span className="icon_profile">
                                    {" "}
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title kt-font-bold">
                                    My Profile
                                  </div>
                                  <div className="kt-notification__item-time">
                                    Account settings and more
                                  </div>
                                </div>
                              </Link>

                              <div className="kt-notification__custom">
                                <Link
                                  to={""}
                                  className="save_button"
                                  onClick={this.signout}
                                >
                                  Sign Out
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                        {/*Control Sidebar Toggle Button -->*/}
                      </ul>
                    </div>
                  </nav>
                </header>

                <Sidebar />
              </div>
            );
          }
        })()}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state,
    isUserLoggedIn: state.user_info,
    userNotification: state.user.get("notificationResult"),

    user_info: localStorage.getItem("u$eRInf"),
  }),
  { ...DashboardAction, ...UserNotification }
)(Header);
