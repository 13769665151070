const actions = {
  INSTRUCTOR_UNIT_TYPE: "INSTRUCTOR_UNIT_TYPE",
  INSTRUCTOR_UNIT_TYPE_SUCCESS: "INSTRUCTOR_UNIT_TYPE_SUCCESS",
  INSTRUCTOR_UNIT_LIST: "INSTRUCTOR_UNIT_LIST",
  INSTRUCTOR_UNIT_LIST_SUCCESS: "INSTRUCTOR_UNIT_LIST_SUCCESS",
  INSTRUCTOR_BATCHES: "INSTRUCTOR_BATCHES",
  INSTRUCTOR_BATCHES_SUCCESS: "INSTRUCTOR_BATCHES_SUCCESS",
  INSTRUCTOR_HOST_ID: "INSTRUCTOR_HOST_ID",
  INSTRUCTOR_HOST_ID_SUCCESS: "INSTRUCTOR_HOST_ID_SUCCESS",
  INSTRUCTOR_QUIZ_LIST: "INSTRUCTOR_QUIZ_LIST",
  INSTRUCTOR_QUIZ_LIST_SUCCESS: "INSTRUCTOR_QUIZ_LIST_SUCCESS",
  INSTRUCTOR_ADD_SECTION: "INSTRUCTOR_ADD_SECTION",
  INSTRUCTOR_ADD_SECTION_SUCCESS: "INSTRUCTOR_ADD_SECTION_SUCCESS",
  INSTRUCTOR_STUDENT_NOTIFICATION: "INSTRUCTOR_STUDENT_NOTIFICATION",
  INSTRUCTOR_STUDENT_NOTIFICATION_SUCCESS:
    "INSTRUCTOR_STUDENT_NOTIFICATION_SUCCESS",
  INSTRUCTOR_ASSIGNMENT_DETAIL: "INSTRUCTOR_ASSIGNMENT_DETAIL",
  INSTRUCTOR_ASSIGNMENT_DETAIL_SUCCESS: "INSTRUCTOR_ASSIGNMENT_DETAIL_SUCCESS",
  INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL: "INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL",
  INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL_SUCCESS:
    "INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL_SUCCESS",
  INSTRUCTOR_VERIFY_ASSIGNMENT: "INSTRUCTOR_VERIFY_ASSIGNMENT",
  INSTRUCTOR_VERIFY_ASSIGNMENT_SUCCESS: "INSTRUCTOR_VERIFY_ASSIGNMENT_SUCCESS",
  INSTRUCTOR_ASSIGNMENT_COMMENT: "INSTRUCTOR_ASSIGNMENT_COMMENT",
  INSTRUCTOR_ASSIGNMENT_COMMENT_SUCCESS:
    "INSTRUCTOR_ASSIGNMENT_COMMENT_SUCCESS",
  INSTRUCTOR_ATTENDANCE_LIST: "INSTRUCTOR_ATTENDANCE_LIST",
  INSTRUCTOR_ATTENDANCE_LIST_SUCCESS: "INSTRUCTOR_ATTENDANCE_LIST_SUCCESS",
  INSTRUCTOR_MYSCHEDULE: "INSTRUCTOR_MYSCHEDULE",
  INSTRUCTOR_MYSCHEDULE_SUCCESS: "INSTRUCTOR_MYSCHEDULE_SUCCESS",
  INSTRUCTOR_QUIZ_DETAILS: "INSTRUCTOR_QUIZ_DETAILS",
  INSTRUCTOR_QUIZ_DETAILS_SUCCESS: "INSTRUCTOR_QUIZ_DETAILS_SUCCESS",
  INSTRUCTOR_QUIZ_QUESTION: "INSTRUCTOR_QUIZ_QUESTION",
  INSTRUCTOR_QUIZ_QUESTION_SUCCESS: "INSTRUCTOR_QUIZ_QUESTION_SUCCESS",
  INSTRUCTOR_STUDENT_LIST_QUIZ: "INSTRUCTOR_STUDENT_LIST_QUIZ",
  INSTRUCTOR_STUDENT_LIST_QUIZ_SUCCESS: "INSTRUCTOR_STUDENT_LIST_QUIZ_SUCCESS",
  INSTRUCTOR_STUDENT_ASSESMENT: "INSTRUCTOR_STUDENT_ASSESMENT",
  INSTRUCTOR_STUDENT_ASSESMENT_SUCCESS: "INSTRUCTOR_STUDENT_ASSESMENT_SUCCESS",
  INSTRUCTOR_VERIFY_QUIZ: "INSTRUCTOR_VERIFY_QUIZ",
  INSTRUCTOR_VERIFY_QUIZ_SUCCESS: "INSTRUCTOR_VERIFY_QUIZ_SUCCESS",
  INSTRUCTOR_QUIZ_QUESTION_LIST: "INSTRUCTOR_QUIZ_QUESTION_LIST",
  INSTRUCTOR_QUIZ_QUESTION_LIST_SUCCESS:
    "INSTRUCTOR_QUIZ_QUESTION_LIST_SUCCESS",
  INSTRUCTOR_GET_QUIZ: "INSTRUCTOR_GET_QUIZ",
  INSTRUCTOR_GET_QUIZ_SUCCESS: "INSTRUCTOR_GET_QUIZ_SUCCESS",
  LANGUAGE_LIST: "LANGUAGE_LIST",
  LANGUAGE_LIST_SUCCESS: "LANGUAGE_LIST_SUCCESS",

  languageList: () => ({
    type: actions.LANGUAGE_LIST,
    payload: {},
  }),

  languageListSuccess: (result) => ({
    type: actions.LANGUAGE_LIST_SUCCESS,
    payload: { result },
  }),

  verifyQuiz: (data) => ({
    type: actions.INSTRUCTOR_VERIFY_QUIZ,
    payload: { data },
  }),

  verifyQuizSuccess: (result) => ({
    type: actions.INSTRUCTOR_VERIFY_QUIZ_SUCCESS,

    payload: { result },
  }),

  QuizAssesment: (quizId, user_Id) => ({
    type: actions.INSTRUCTOR_STUDENT_ASSESMENT,
    payload: { quizId, user_Id },
  }),

  QuizAssesmentSuccess: (result) => ({
    type: actions.INSTRUCTOR_STUDENT_ASSESMENT_SUCCESS,
    payload: { result },
  }),

  studentListQuizDetails: (quizId, batchId) => ({
    type: actions.INSTRUCTOR_STUDENT_LIST_QUIZ,
    payload: { quizId, batchId },
  }),

  studentListQuizDetailSuccess: (result) => ({
    type: actions.INSTRUCTOR_STUDENT_LIST_QUIZ_SUCCESS,
    payload: { result },
  }),

  QuizQuestion: (quizId) => ({
    type: actions.INSTRUCTOR_QUIZ_QUESTION,
    payload: { quizId },
  }),

  QuizQuestionSuccess: (result) => ({
    type: actions.INSTRUCTOR_QUIZ_QUESTION_SUCCESS,
    payload: { result },
  }),
  QuizDetails: (quiz_id) => ({
    type: actions.INSTRUCTOR_QUIZ_DETAILS,
    payload: { quiz_id },
  }),

  QuizDetailSuccess: (result) => ({
    type: actions.INSTRUCTOR_QUIZ_DETAILS_SUCCESS,
    payload: { result },
  }),

  quizListQuestions: () => ({
    type: actions.INSTRUCTOR_QUIZ_QUESTION_LIST,
    payload: {},
  }),

  quizListQuestionSuccess: (result) => ({
    type: actions.INSTRUCTOR_QUIZ_QUESTION_LIST_SUCCESS,
    payload: { result },
  }),

  getInstructorQuizLists: () => ({
    type: actions.INSTRUCTOR_GET_QUIZ,
    payload: {},
  }),

  getInstructorQuizListSuccess: (result) => ({
    type: actions.INSTRUCTOR_GET_QUIZ_SUCCESS,
    payload: { result },
  }),

  QuizList: () => ({
    type: actions.INSTRUCTOR_QUIZ,
    payload: {},
  }),

  QuizListSuccess: (result) => ({
    type: actions.INSTRUCTOR_QUIZ_SUCCESS,
    payload: { result },
  }),

  myScheduleList: () => ({
    type: actions.INSTRUCTOR_MYSCHEDULE,
    payload: {},
  }),

  myScheduleListSuccess: (result) => ({
    type: actions.INSTRUCTOR_MYSCHEDULE_SUCCESS,
    payload: { result },
  }),

  attendanceList: () => ({
    type: actions.INSTRUCTOR_ATTENDANCE_LIST,
    payload: {},
  }),

  attendanceListSuccess: (result) => ({
    type: actions.INSTRUCTOR_ATTENDANCE_LIST_SUCCESS,
    payload: { result },
  }),

  instructorUnitType: () => ({
    type: actions.INSTRUCTOR_UNIT_TYPE,
    payload: {},
  }),

  checkAssignmentComment: (data) => ({
    type: actions.INSTRUCTOR_ASSIGNMENT_COMMENT,
    payload: { data },
  }),

  checkAssignmentCommentSuccess: (result) => ({
    type: actions.INSTRUCTOR_ASSIGNMENT_COMMENT_SUCCESS,
    payload: { result },
  }),

  viewAssigmentData: (assignmentId) => ({
    type: actions.INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL,
    payload: { assignmentId },
  }),

  verifyAssignments: (user_assignment_id) => ({
    type: actions.INSTRUCTOR_VERIFY_ASSIGNMENT,
    payload: { user_assignment_id },
  }),

  verifyAssignmentSuccess: (result) => ({
    type: actions.INSTRUCTOR_VERIFY_ASSIGNMENT_SUCCESS,
    payload: { result },
  }),

  viewAssigmentDataSuccess: (result) => ({
    type: actions.INSTRUCTOR_ASSIGNMENT_VIEW_DETAIL_SUCCESS,
    payload: { result },
  }),

  assignmentDetails: () => ({
    type: actions.INSTRUCTOR_ASSIGNMENT_DETAIL,
    payload: {},
  }),

  assignmentDetailsSuccess: (result) => ({
    type: actions.INSTRUCTOR_ASSIGNMENT_DETAIL_SUCCESS,
    payload: { result },
  }),

  sendStudentNotification: (data) => ({
    type: actions.INSTRUCTOR_STUDENT_NOTIFICATION,
    payload: { data },
  }),

  sendStudentNotificationSuccess: (result) => ({
    type: actions.INSTRUCTOR_STUDENT_NOTIFICATION_SUCCESS,
    payload: { result },
  }),

  instructorUnitList: () => ({
    type: actions.INSTRUCTOR_UNIT_LIST,
    payload: {},
  }),

  quizLists: () => ({ type: actions.INSTRUCTOR_QUIZ_LIST, payload: {} }),

  quizListsSuccess: (result) => ({
    type: actions.INSTRUCTOR_QUIZ_LIST_SUCCESS,
    payload: { result },
  }),

  InstructorAddSections: (data) => ({
    type: actions.INSTRUCTOR_ADD_SECTION,
    payload: { data },
  }),

  InstructorAddSectionSuccess: (result) => ({
    type: actions.INSTRUCTOR_ADD_SECTION_SUCCESS,
    payload: { result },
  }),

  instructorBatch: () => ({ type: actions.INSTRUCTOR_BATCHES, payload: {} }),

  instructorHostId: () => ({ type: actions.INSTRUCTOR_HOST_ID, payload: {} }),

  instructorHostIdSuccess: (result) => ({
    type: actions.INSTRUCTOR_HOST_ID_SUCCESS,
    payload: { result },
  }),

  instructorBatchSuccess: (result) => ({
    type: actions.INSTRUCTOR_BATCHES_SUCCESS,
    payload: { result },
  }),

  instructorUnitListSuccess: (result) => ({
    type: actions.INSTRUCTOR_UNIT_LIST_SUCCESS,
    payload: { result },
  }),

  instructorUnitTypeSuccess: (result) => ({
    type: actions.INSTRUCTOR_UNIT_TYPE_SUCCESS,
    payload: { result },
  }),
};
export default actions;
