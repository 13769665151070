import React from "react";
import ReactDOM from "react-dom";
import DashApp from "./dashApp";
// import createHistory from "history/createBrowserHistory";
import history from "./history";
// import history from "history/createBrowserHistory";

// const history = createHistory();
// import 'antd/dist/antd.css';
ReactDOM.render(<DashApp history={history} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
