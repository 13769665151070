import React, { Component } from "react";
// import Chatapp from "../Page/chatapp";

class Footer extends Component {
  render() {
    // const toggleChatboxBtn = document.querySelector(".js-chatbox-toggle");
    // const chatbox = document.querySelector(".js-chatbox");
    // const chatboxMsgDisplay = document.querySelector(".js-chatbox-display");
    // const chatboxForm = document.querySelector(".js-chatbox-form");

    // toggleChatboxBtn.addEventListener("click", () => {
    //   chatbox.classList.toggle("chatbox--is-visible");

    //   if (chatbox.classList.contains("chatbox--is-visible")) {
    //     toggleChatboxBtn.innerHTML = '<i className="fas fa-chevron-down"></i>';
    //   } else {
    //     toggleChatboxBtn.innerHTML = '<i className="fas fa-chevron-up"></i>';
    //   }
    // });

    return (
      <footer className="main-footer">
        <div className="pull-right hidden-xs"></div>
        <strong>
          Copyright &copy; 2020 <a href="#">Qversity</a>.
        </strong>{" "}
        All rights reserved.
        {/* chat box */}
        {/* end other chat box */}
        <>{/*  <Chatapp />*/}</>
      </footer>
    );
  }
}
export default Footer;
