const actions = {
  USER_COURSES: "USER_COURSES",
  USER_COURSES_SUCCESS: "COURSE_CATEGORY_SUCCESS",
  INSTRUCTOR_COURSES: "INSTRUCTOR_COURSES",
  INSTRUCTOR_COURSES_SUCCESS: "INSTRUCTOR_COURSES_SUCCESS",
  SECTION_COMPILER_LIST: "SECTION_COMPILER_LIST",
  SECTION_COMPILER_LIST_SUCCESS: "SECTION_COMPILER_LIST_SUCCESS",

  userCourses: (userid) => ({
    type: actions.USER_COURSES,
    payload: { userid },
  }),
  sectionList: () => ({
    type: actions.SECTION_COMPILER_LIST,
    payload: {},
  }),
  sectionListSuccess: (result) => ({
    type: actions.SECTION_COMPILER_LIST_SUCCESS,
    payload: { result },
  }),
  userCoursesSuccess: (result) => ({
    type: actions.USER_COURSES_SUCCESS,
    payload: { result },
  }),

  instructorCourses: () => ({
    type: actions.INSTRUCTOR_COURSES,
    payload: {},
  }),
  instructorCoursesSuccess: (result) => ({
    type: actions.INSTRUCTOR_COURSES_SUCCESS,
    payload: { result },
  }),
};
export default actions;
