import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import LeftIcon from "../../image/left.png";
import appActions from "../../redux/app/actions";
import RightIcon from "../../image/righticon.png";
import { connect } from "react-redux";

const { pdfSliderOnCardSuccess } = appActions;

class PdfReader extends Component {


}

export default connect(
  (state) => ({
    app: state.App,
    height: state.App.height,
    pdfSlideViewArrow: state.App.pdfSliderValue,
  }),
  { pdfSliderOnCardSuccess }
)(PdfReader);
