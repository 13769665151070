import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import CourseApi from "../../helpers/courseApi";

import actions from "./actions";

export function* getCourseDeatils() {
  yield takeEvery(actions.COURSE_DETAIL, function*({ payload }) {
    try {
      const result = yield call(CourseApi.getCourseDetail, payload.course_slug);

      if (result.status_code === 200) {
        yield put(actions.courseDetailSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getContentDeatils() {
  yield takeEvery(actions.CONTENT_DETAIL, function*({ payload }) {
    try {
      const result = yield call(CourseApi.getContentDetail, payload.content_id);

      if (result.status_code === 200) {
        yield put(actions.contentDetailSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getCourseDeatils), fork(getContentDeatils)]);
}
