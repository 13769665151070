
import React, { Component } from "react";

import Header from "./header";
import SignupFormContainer from "../SignupFormContainer";

import Footer from "./footer";

class Profile extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          {/*-- Content Header (Page header) -->*/}

          {/*-- Main content -->*/}
          <section className="content equal-cols container-fluid">
            <SignupFormContainer />
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Profile;
