import React, { Component } from "react";
import { connect } from "react-redux";
import UserActions from "../redux/user/actions";
// import { Link } from "react-router-dom";
import {
  CountryDropdown,
  RegionDropdown,
  // CountryRegionData,
} from "react-country-region-selector";
import AvatarImage from "../image/avatar.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

import { validationObj } from "../utils/Validation";
import { data } from "../utils/country_dialcode";
import { header, user } from "../config";

class SignupFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUser: {
        email: "",
        first_name: "",
        mobile_number: "",
        last_name: "",
        country: "",
        pin_code: "",
        state: "",
        city: "",
        qualification: "",
        gender: "",
        date_of_birth: new Date(),
      },

      additional_info: {
        address_line: "",
        linkedin_profile: "",
        organisation: "",
        professtion_type: "",
        facebook_profile: "",
        institute_name: "",
      },

      change_password: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      currentNo: "",
      formErrors: { email: "", name: "", password: "" },
      emailValid: false,
      nameValid: false,
      passwordValid: false,
      formValid: false,
      professionList: {},
      errors: {},
      countryList: {},
      stateList: {},
      otpValue: "",
      credentials: {
        email: "",
        password: "",
      },
      responseError: { isSuccess: false, isError: false, code: "", text: "" },
      isLoading: false,
      startDate: new Date(),
      countryCode: "",
      pageLoad: true,
      serverOTP: "",
      otpError: false,
      errorMsg: "",
      resendButton: false,
      otpsuccessMsg: "",
      time: {},
      seconds: 30,
      errPasswordMess: "",
      errPassword: false,
      succPasswordMess: "",
      succPassword: false,
      succAdditional: false,
      succAdditionalMess: "",
      formIsValid: true,
    };

    this.timer = 0;
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    // this.sendOtp = this.sendOtp.bind(this);
    this.handleAdditionalInfo = this.handleAdditionalInfo.bind(this);
    this.handleAdditionalValidation = this.handleAdditionalValidation.bind(
      this
    );
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState((prevState) => ({
      newUser: {
        ...prevState.newUser,
        [name]: value,
      },
    }));
  }

  handlePasswordInput(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState((prevState) => ({
      change_password: {
        ...prevState.change_password,
        [name]: value,
      },
    }));
  }

  handleAdditionalInfo(e) {
    let value = e.target.value;
    let name = e.target.name;

    if (name === "professtion_type") {
      if (value === 1 || value === 3) {
        $("#organisation").attr("placeholder", "Name of your College");
      } else if (value === 2) {
        $("#organisation").attr("placeholder", "Name of your Organization");
      } else {
      }
    }

    this.setState((prevState) => ({
      additional_info: {
        ...prevState.additional_info,
        [name]: value,
      },
    }));
  }

  selectCountry(value) {
    $(".country").removeClass("has_error");
    if (value === "") {
      this.setState({ countryCode: "+91" });
    }
    Object.keys(data).map((key) => {
      if (key === value) {
        this.setState({ countryCode: data[key].code });
      }
    });
    let name = "country";
    this.setState((prevState) => ({
      newUser: {
        ...prevState.newUser,
        [name]: value,
      },
    }));
  }

  selectRegion(value) {
    //this.setState({ region: value });
    let name = "state";
    this.setState((prevState) => ({
      newUser: {
        ...prevState.newUser,
        [name]: value,
      },
    }));
  }

  handleDatePicker(value) {
    let name = "date_of_birth";
    this.setState((prevState) => ({
      newUser: {
        ...prevState.newUser,
        [name]: value,
      },
    }));
    this.setState({
      startDate: value,
    });
  }
  componentDidMount() {
    const { getUserProfile, getProfessionList } = this.props;
    getUserProfile();
    getProfessionList();
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (seconds === 0) {
      clearInterval(this.timer);
      this.setState({ resendButton: true, seconds: 30 });
    }
  }

  componentWillReceiveProps(nextProps) {
    const userProfileData = nextProps.userProfileData;
    let dateOfBirth = "";
    if (
      userProfileData.user_info &&
      userProfileData.user_info.date_of_birth !== "0000-00-00" &&
      userProfileData.user_info.date_of_birth !== undefined
    ) {
      dateOfBirth = new Date(userProfileData.user_info.date_of_birth);
    } else {
      dateOfBirth = new Date();
    }

    this.setState({
      newUser: {
        first_name: userProfileData.user_info
          ? userProfileData.user_info.first_name
          : "",
        last_name: userProfileData.user_info
          ? userProfileData.user_info.last_name
          : "",

        email: userProfileData ? userProfileData.email : "",
        mobile_number: userProfileData ? userProfileData.mobile_number : "",
        // institute_name: (userProfileData) ? userProfileData.institute_name : '',
        pin_code: userProfileData.user_info
          ? userProfileData.user_info.pin_code
          : "",
        address_line: userProfileData.user_info
          ? userProfileData.user_info.address_line
          : "",
        // organisation: (userProfileData.user_info) ? userProfileData.user_info.organisation : '',

        qualification: userProfileData.user_info
          ? userProfileData.user_info.qualification
          : "",
        // linkedin_profile: (userProfileData.user_info) ? userProfileData.user_info.linkedin_profile : '',
        gender: userProfileData.user_info
          ? userProfileData.user_info.gender
          : "",
        state: userProfileData.user_info ? userProfileData.user_info.state : "",
        city: userProfileData.user_info ? userProfileData.user_info.city : "",
        country: userProfileData.user_info
          ? userProfileData.user_info.country
          : "",
        date_of_birth: dateOfBirth,
        // professtion_type: (userProfileData.user_info) ? userProfileData.user_info.professtion_type : '',
      },

      additional_info: {
        address_line: userProfileData.user_info
          ? userProfileData.user_info.address_line
          : "",
        organisation: userProfileData.user_info
          ? userProfileData.user_info.organisation
          : "",
        professtion_type: userProfileData.user_info
          ? userProfileData.user_info.professtion_type
          : "",
        linkedin_profile: userProfileData.user_info
          ? userProfileData.user_info.linkedin_profile
          : "",
        institute_name: userProfileData ? userProfileData.institute_name : "",
        facebook_profile: userProfileData ? userProfileData.facebook_id : "",
      },

      country: userProfileData.user_info
        ? userProfileData.user_info.country
        : "",
      region: userProfileData.user_info ? userProfileData.user_info.state : "",
      currentNo: userProfileData ? userProfileData.mobile_number : "",
    });

    if (userProfileData.user_info && userProfileData.user_info.country !== "") {
      Object.keys(data).map((key) => {
        if (key === userProfileData.user_info.country) {
          this.setState({ countryCode: data[key].code });
        }
      });
    }

    /*

        if(this.state.pageLoad == true){
            if(this.state.newUser.address_line == ''){
                $(".address_line").addClass('has_error');
            }
            this.setState({pageLoad : false});
        }
        if(this.state.pageLoad == false){
            $(".address_line").removeClass('has_error');
        }
        */
  }

  setBorder(fieldName) {
    $("." + fieldName).addClass("has_error");
    return "";
  }

  // matchOtp() {
  //   this.setState({ otpError: false });
  //   $("#otp")
  //     .removeClass("has_error")
  //     .addClass("noerror");
  //   if (this.state.otpValue == "") {
  //     //this.setState({otpError:true,otpsuccessMsg : "Enter otp"})
  //     $("#otp")
  //       .removeClass("noerror")
  //       .addClass("has_error");
  //   } else {
  //     let matchOtp = user.matchOtp;
  //     let userData = this.state.newUser;

  //     fetch(matchOtp, {
  //       method: "post",
  //       headers: header,
  //       body: JSON.stringify({
  //         otp: this.state.otpValue,
  //         mobile_no: userData.mobile_number,
  //       }),
  //     })
  //       .then(function(response) {
  //         if (response.status >= 400) {
  //           throw new Error("Bad response from server.Refresh your page");
  //         }
  //         return response.json();
  //       })

  //       .then((res) => {
  //         $("#otp")
  //           .addClass("noerror")
  //           .removeClass("has_error");
  //         if (res.status_code != 200) {
  //           this.setState({ otpError: true, otpsuccessMsg: res.message });
  //           $("#otp")
  //             .removeClass("noerror")
  //             .addClass("has_error");
  //         } else {
  //           $("#verifyotp").attr({ style: "display:none" });

  //           const { userProfileUpdate } = this.props;
  //           userProfileUpdate(userData);
  //           this.setState({ otpError: false });
  //         }
  //       });
  //   }
  // }

  // sendOtp() {
  //   let userData = this.state.newUser;
  //   $("#verifyotp").attr({ style: "display:block" });
  //   // this.setState({resendButton : false})
  //   this.setState({ resendButton: false, seconds: 30 });
  //   $("#otp")
  //     .removeClass("has_error")
  //     .addClass("noerror");
  //   // this.setState({resendButton : false,seconds:30})
  //   clearInterval(this.timer);
  //   this.timer = 0;
  //   let timeLeftVar = this.secondsToTime(30);
  //   this.setState({ time: timeLeftVar, otpError: false });
  //   this.startTimer();
  //   let getOtp = user.sendOtp;
  //   fetch(getOtp, {
  //     method: "post",
  //     headers: header,
  //     body: JSON.stringify({ mobile_no: userData.mobile_number }),
  //   })
  //     .then(function(response) {
  //       if (response.status >= 400) {
  //         throw new Error("Bad response from server.Refresh your page");
  //       }
  //       return response.json();
  //     })

  //     .then((res) => {});
  // }

  handleFormSubmit(e) {
    e.preventDefault();

    let userData = this.state.newUser;
    $("html,body").animate({ scrollTop: 0 }, 0);

    if (this.handleValidation()) {
      // if (this.state.currentNo !== userData.mobile_number) {
      //   this.setState({ otpValue: "", otpError: false });
      //   $("#otp")
      //     .addClass("noerror")
      //     .removeClass("has_error");

      // let getOtp = user.sendOtp;

      // fetch(getOtp, {
      //   method: "post",
      //   headers: header,
      //   body: JSON.stringify({ mobile_no: userData.mobile_number }),
      // })
      //   .then(function(response) {
      //     if (response.status >= 400) {
      //       throw new Error("Bad response from server.Refresh your page");
      //     }
      //     return response.json();
      //   })
      //   .then((res) => {
      //     console.log("res");
      //     console.log(res);

      //     if (res.status_code == 200) {
      //       $("#verifyotp").attr({ style: "display:block" });
      //       this.setState({ resendButton: false, seconds: 30 });

      //       // this.setState({resendButton : false,seconds:30})
      //       this.timer = 0;
      //       let timeLeftVar = this.secondsToTime(30);
      //       this.setState({ time: timeLeftVar });
      //       this.startTimer();
      //     }
      //     // this.setState({serverOTP:res.otp});
      //     // console.log(res.otp);
      //   });
      // } else {
      const { userProfileUpdate } = this.props;
      userProfileUpdate(userData);
      // }

      // const { userProfileUpdate } = this.props;
      // userProfileUpdate(userData);
    }
  }

  handleAdditionalValidation() {
    let additional_info = this.state.additional_info;
    let errors = {};
    let formIsValid = true;
    // let isError = false;

    // if (
    //   additional_info["professtion_type"] == null ||
    //   additional_info["professtion_type"] == ""
    // ) {
    //   formIsValid = false;
    //   errors["professtion_type"] = "Please enter profesion !";
    // } else if (
    //   additional_info["address_line"] == null ||
    //   additional_info["address_line"] == ""
    // ) {
    //   formIsValid = false;
    //   errors["address_line"] = "Please enter address !";
    // } else if (
    //   additional_info["organisation"] == null ||
    //   additional_info["organisation"] == ""
    // ) {
    //   formIsValid = false;
    //   errors["organisation"] = "Please enter organisation !";
    // }

    const responseError = { isError: false, code: "", text: "" };
    this.setState({ responseError });

    errors = validationObj.isEmpty(
      additional_info["professtion_type"],
      "professtion_type",
      "profileInfo",
      1
    );
    errors = validationObj.isEmpty(
      additional_info["address_line"],
      "address_line",
      "profileInfo",
      1
    );

    errors = validationObj.isEmpty(
      additional_info["linkedin_profile"],
      "linkedin_profile",
      "profileInfo",
      1
    );
    errors = validationObj.isEmpty(
      additional_info["facebook_profile"],
      "facebook_profile",
      "profileInfo",
      1
    );

    errors = validationObj.isEmpty(
      additional_info["organisation"],
      "organisation",
      "profileInfo",
      1
    );

    if (
      errors.professtion_type !== "" ||
      errors.professtion_type === "Professtion_type cannot be empty" ||
      errors.address_line !== "" ||
      errors.address_line === "Address_line cannot be empty" ||
      errors.organisation !== "" ||
      errors.organisation === "Organisation cannot be empty"
    ) {
      formIsValid = false;
    }
    this.setState({ errors: errors, formIsValid });
    return formIsValid;
  }

  handleAdditionalFormSubmit(e) {
    e.preventDefault();

    let userAdditionalData = this.state.additional_info;
    $("html,body").animate({ scrollTop: 0 }, 0);

    if (this.handleAdditionalValidation()) {
      const addtionalInfo = user.userAdditionalInfo;
      fetch(addtionalInfo, {
        method: "PUT",
        headers: header,
        body: JSON.stringify(userAdditionalData),
      })
        .then(function(response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server.Refresh your page");
          }
          return response.json();
        })

        .then((res) => {
          if (res.status_code === 200) {
            this.setState({
              succAdditional: true,
              succAdditionalMess: res.message,
            });
          }
        });
    }
  }

  handlePasswordFormSubmit(e) {
    e.preventDefault();
    let userPasswordData = this.state.change_password;

    let err = 0;
    if (userPasswordData["password"] === "") {
      $(".password").addClass("has_error");
      err = 1;
    } else {
      $(".password").removeClass("has_error");
      err = 0;
    }
    if (userPasswordData["old_password"] === "") {
      $(".old_password").addClass("has_error");
      err = 1;
    } else {
      $(".old_password").removeClass("has_error");
      err = 0;
    }
    if (userPasswordData["password_confirmation"] === "") {
      $(".password_confirmation").addClass("has_error");
      err = 1;
    } else {
      $(".password_confirmation").removeClass("has_error");
      err = 0;
    }

    if (
      userPasswordData["password_confirmation"] !== userPasswordData["password"]
    ) {
      this.setState({ errPassword: false });
      $(".password_confirmation").addClass("has_error");
      $(".password").addClass("has_error");
      err = 1;
    }

    if (err === 1) {
      return false;
    }

    $("html,body").animate({ scrollTop: 0 }, 0);

    const userUpdatePassword = user.userUpdatePassword;
    fetch(userUpdatePassword, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(userPasswordData),
    })
      .then(function(response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server.Refresh your page");
        }
        return response.json();
      })
      .then((res) => {
        if (res.status_code !== 200) {
          this.setState({
            errPassword: true,
            errPasswordMess: res.message,
            succPassword: false,
          });
        } else {
          this.setState({
            errPassword: false,
            succPassword: true,
            errPasswordMess: "",
            succPasswordMess: res.message,
          });
          this.setState({
            change_password: {
              old_password: "",
              password: "",
              password_confirmation: "",
            },
          });

          localStorage.setItem("u$tOken", `Bearer ${res.data.token}`);
          let user_token = localStorage.getItem("u$tOken");
          let TOKEN_STRING = user_token;
          header.Authorization = TOKEN_STRING;
        }
      });
  }

  handleValidation() {
    let newUser = this.state.newUser;

    let errors = {};
    let formIsValid = true;
    // let isError = false;

    const responseError = { isError: false, code: "", text: "" };
    this.setState({ responseError });

    errors = validationObj.isValidName(
      newUser["first_name"],
      "first_name",
      "profileInfo",
      1
    );
    errors = validationObj.isValidName(
      newUser["last_name"],
      "last_name",
      "profileInfo",
      1
    );
    errors = validationObj.isValidPhone(
      newUser["mobile_number"],
      "mobile_number",
      "profileInfo",
      1
    );
    errors = validationObj.isEmpty(newUser["state"], "state", "profileInfo", 1);
    errors = validationObj.isEmpty(
      newUser["gender"],
      "gender",
      "profileInfo",
      1
    );
    errors = validationObj.isEmpty(
      newUser["country"],
      "country",
      "profileInfo",
      1
    );
    errors = validationObj.isEmpty(newUser["state"], "state", "profileInfo", 1);

    errors = validationObj.isValidName(
      newUser["city"],
      "city",
      "profileInfo",
      1
    );

    // errors = validationObj.isValidZipCode(newUser["pin_code"], 'pin_code', 'profileInfo', 1);
    errors = validationObj.isEmpty(
      newUser["date_of_birth"],
      "date_of_birth",
      "profileInfo",
      1
    );

    if (
      errors.first_name !== "" ||
      errors.first_name === "First_name cannot be empty" ||
      errors.last_name !== "" ||
      errors.last_name === "Last_name cannot be empty" ||
      errors.mobile_number !== "" ||
      errors.mobile_number === "Mobile_number cannot be empty" ||
      errors.state !== "" ||
      errors.state === "State cannot be empty" ||
      errors.city !== "" ||
      errors.city === "City cannot be empty" ||
      errors.gender !== "" ||
      errors.gender === "Gender cannot be empty" ||
      errors.country !== "" ||
      errors.country === "Country cannot be empty"
    ) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  closeNotifyModel() {
    const { getUserProfile } = this.props;
    clearInterval(this.timer);
    this.timer = 0;
    getUserProfile();
    $("#verifyotp").attr({ style: "display:none" });
  }

  // function() {
  //     $( "#accordion" ).accordion({active: 0});
  // };

  accountSetting(e) {
    e.preventDefault();
    const divId = e.currentTarget.dataset.id;
    $(".profile_update").attr("style", "display:none");
    $("ul.profile_menu > li a").removeClass("menu_active");
    $("#messageInfo").attr("style", "display:none");
    $("#" + divId).attr("style", "display:block");
    $("." + divId).addClass("menu_active");

    this.setState({
      succAdditional: false,
      errPassword: false,
      succPassword: false,
      change_password: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
    });
  }

  render() {
    // const { errors, isValid } = this.state;
    // const successMsg = this.props.userUpdateData;
    const professionList = this.props.professionListData;
    // const getMobileOtp = this.props.getMobileOtpData;
    const userName = localStorage.getItem("u$eRInf");
    const { userProfileData } = this.props;
    let rows = [];
    const roleName = localStorage.getItem("role_name");
    var firstLastName = userProfileData && userName;
    return (
      <div id="profileInfo" className="profile-wrapper">
        <div className="row">
          <div className="col-lg-3 profileSideBar">
            <div className="white_bg_cntr clearfix">
              <div className="profile_side_bar ">
                {/* <h3>Your Profile</h3> */}

                <div className="picture-container">
                  <div className="picture">
                    <img
                      src={AvatarImage}
                      className="picture-src"
                      id="wizardPicturePreview"
                      title=""
                      alt="wizard"
                    />
                    <input type="file" id="wizard-picture" />
                  </div>
                  {/* <h6>Upload Picture</h6> */}
                </div>
                <div className="profile_input text-center">
                  <h2>
                    {this.state.newUser.first_name}
                    {/* ? this.state.newUser.first_name
                      : firstLastName[0]}{" "}
                    {this.state.newUser.last_name
                      ? this.state.newUser.last_name
                      : firstLastName[1]} */}
                  </h2>
                  <span className="instructorName">
                    {roleName ? roleName.trim('"') : roleName}
                  </span>
                  {/* <input type="text" placeholder="Demo Person"/> */}
                </div>

                <ul className="profile_menu">
                  <li>
                    <a
                      href="/#"
                      className="submenu_active_profile personal_info menu_active"
                      data-id={"personal_info"}
                      href="javascript:void(0)"
                      onClick={this.accountSetting.bind(this)}
                    >
                      <span>
                        <i className="far fa-user"></i>
                      </span>
                      Personal Information
                    </a>
                  </li>
                  {/*<li className="sideBottom">
                            <a
                              className={"education_detail"}
                              data-id={"education_detail"}
                              href="javascript:void(0)"
                              onClick={this.accountSetting.bind(this)}
                            >
                              Education Details
                            </a>
                          </li>
                         <li><a data-id={"account_setting"} href="javascript:void(0)" onClick={this.accountSetting.bind(this)}>Account Setting</a></li>*/}
                  <li>
                    <a
                      href="/#"
                      className={"change_password"}
                      data-id={"change_password"}
                      href="javascript:void(0)"
                      onClick={this.accountSetting.bind(this)}
                    >
                      <span>
                        <i className="fas fa-lock"></i>
                      </span>
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#"
                      className={"additional_info"}
                      data-id={"additional_info"}
                      href="javascript:void(0)"
                      onClick={this.accountSetting.bind(this)}
                    >
                      <span>
                        <i className="far fa-address-card"></i>
                      </span>
                      Additional Information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <div
              className="white_bg_cntr clearfix profile_update"
              id={"personal_info"}
            >
              <div
                className="alert "
                style={{ display: "none" }}
                id="messageInfo"
              ></div>

              <form onSubmit={this.handleFormSubmit} className="profile_box">
                <div className="sectionHeading">Personal Information</div>
                <div className="profile-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            title={"First Name"}
                            className="form-control first_name"
                            name={"first_name"}
                            value={this.state.newUser.first_name}
                            onChange={this.handleInput}
                          />
                          {/*<span style={{ color: 'red' }} className="alert">{this.state.errors["name"]}</span>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">Last Name</label>

                          <input
                            type="text"
                            id="last_name"
                            title={"Last Name"}
                            className="form-control last_name"
                            name={"last_name"}
                            value={this.state.newUser.last_name}
                            onChange={this.handleInput}
                          />
                          {/*<span style={{ color: 'red' }} className="alert">{this.state.errors["name"]}</span>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            {" "}
                            Your Email
                          </label>

                          <input
                            type="email"
                            id="inputEmail"
                            className="form-control email"
                            required="required"
                            name={"email"}
                            value={this.state.newUser.email}
                            placeholder={"Email address"}
                            onChange={this.handleInput}
                            readOnly={"readonly"}
                          />
                          {/*} <span style={{ color: 'red' }} className="alert">{this.state.errors["email"]}</span>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_input   profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            {" "}
                            Your Phone Number
                          </label>

                          <div className="country_input">
                            <span
                              className="input-group-addon country_code"
                              id="basic-addon1"
                            >
                              {this.state.countryCode}
                            </span>
                            <input
                              type="text"
                              className="form-control mobile_number"
                              id={"phone"}
                              name={"mobile_number"}
                              value={
                                this.state.newUser.mobile_number
                                  ? this.state.newUser.mobile_number
                                  : ""
                              }
                              onChange={this.handleInput}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_select profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            Your Gender
                          </label>

                          <select
                            className={"gender"}
                            name="gender"
                            id="gender"
                            onChange={this.handleInput}
                            value={
                              this.state.newUser.gender
                                ? this.state.newUser.gender
                                : ""
                            }
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>

                          {/*} <span style={{ color: "red" }} className="alert">{this.state.errors["gender"]}</span>*/}
                        </div>
                      </div>
                    </div>

                    <div className={"col-lg-4"}>
                      <div className="profile_input profile_daterpicker profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            {" "}
                            Your Birthday
                          </label>

                          <div className="form-group input-group date">
                            <DatePicker
                              name="date_of_birth"
                              className="form-control date_of_birth datepicker"
                              selected={this.state.newUser.date_of_birth}
                              onChange={this.handleDatePicker}
                              dateFormat="MM-dd-yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_select profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            Your Country
                          </label>

                          <CountryDropdown
                            value={
                              this.state.newUser.country
                                ? this.state.newUser.country
                                : ""
                            }
                            onChange={this.selectCountry}
                            showDefaultOption={true}
                            name={"country"}
                            id={"country"}
                            valueType={"short"}
                            classes={"country"}
                          />
                          {/* <span style={{ color: "red" }} className="alert">{this.state.errors["state"]}</span>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_select profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">
                            Your State
                          </label>

                          <RegionDropdown
                            country={this.state.newUser.country}
                            value={
                              this.state.newUser.state
                                ? this.state.newUser.state
                                : ""
                            }
                            onChange={this.selectRegion}
                            name={"state"}
                            id={"state"}
                            defaultOptionLabel={"Select State"}
                            blankOptionLabel={"Select State"}
                            valueType={"full"}
                            countryValueType={"short"}
                            classes={"state"}
                          />
                          {/* <span style={{ color: "red" }} className="alert">{this.state.errors["state"]}</span>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr">
                        <div className="form-group">
                          <label className="profile-top-label">Your City</label>

                          <input
                            type="text"
                            className="form-control city"
                            id="city"
                            name={"city"}
                            value={this.state.newUser.city}
                            onChange={this.handleInput}
                            required={""}
                            autoComplete={"off   "}
                          />
                          {/* <span style={{ color: "red" }} className="alert">{this.state.errors["city"]}</span>
                           */}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 button-group">
                      <button
                        className="save_button"
                        onClick={this.handleFormSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              className="white_bg_cntr clearfix profile_update"
              style={{ display: "none" }}
              id={"account_setting"}
            >
              <section className="content equal-cols container-fluid">
                <div className="inner_content_cntr white_bg_cntr">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="finance_model_box inner_heading">
                          <h3>Account Setting</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile_box account_box">
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="left_part">
                          <h4>Notification Sound</h4>
                          <p>
                            A sound will be played each time you receive a new
                            activity notification
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="switch_button pull-right">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-9">
                        <div className="left_part">
                          <h4>Notification Sound</h4>
                          <p>
                            A sound will be played each time you receive a new
                            activity notification
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="switch_button">
                          <label className="switch pull-right">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-9">
                        <div className="left_part">
                          <h4>Notification Sound</h4>
                          <p>
                            A sound will be played each time you receive a new
                            activity notification
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="switch_button pull-right">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <input
                          className="save_button"
                          type="submit"
                          value="Save All Change"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* education detail */}
            <div
              className="white_bg_cntr clearfix profile_update"
              style={{ display: "none" }}
              id={"education_detail"}
            >
              <div className="container-fluid">
                <div className="row hedr_bg">
                  <div className="col-lg-12">
                    <div className="finance_model_box inner_heading">
                      <h3>Education Details</h3>
                    </div>
                  </div>
                </div>
              </div>
              <section className="content equal-cols container-fluid">
                <div className="inner_content_cntr white_bg_cntr">
                  <div className="row productdetail">
                    <div className="col-md-12 col-sm-12 ">
                      <div
                        className="panel-group"
                        id="accordion"
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            role="tab"
                            id="retailer"
                          >
                            <h4 className="panel-title colge_img">
                              <a
                                href="/#"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                className="collapsed"
                              >
                                <img
                                  src="../images/galgotia.png"
                                  className="img-fluid"
                                  alt="galgotia"
                                />
                              </a>
                            </h4>
                            <div className="user_clg_dtl">
                              <h3>Galgotias Educational Institute</h3>
                              <p>
                                Master, Management information System
                                <br />
                                2015 - 2017
                              </p>
                            </div>
                          </div>
                          <div
                            id="collapseOne"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="retailer"
                          >
                            <div className="">
                              <div className="marksheet_header text-center">
                                <img
                                  src="../images/galgotia.png"
                                  className="img-fluid"
                                  alt="galgotia one"
                                />
                                <div className="tag_head">
                                  <h1>Galgotias Group Of Institutaion</h1>
                                  <h4>
                                    ( Deemed to Be University ) <br />
                                    (Accredited by NAAC with Grade 'A')
                                  </h4>
                                </div>
                              </div>
                              <br />

                              <form
                                onSubmit={this.handleFormSubmit}
                                className="profile_box"
                              >
                                <div className="student_detail_sec">
                                  <div className="name_sec pull-left">
                                    <h3>
                                      Student's Name :
                                      {this.state.newUser.first_name}
                                      {this.state.newUser.last_name} <br />
                                      <br />
                                      Enroll No. : 2019-303-403
                                    </h3>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2160;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester2"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester2"
                                >
                                  Semester <span>&#x2161;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester3"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester3"
                                >
                                  Semester <span>&#x2162;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2163;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2164;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2165;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2166;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2167;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2168;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2169;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  <i className="ion-plus"></i>
                                </button>
                                <div
                                  className="collapse1"
                                  id="collapseSemester"
                                >
                                  <div className="card card-body semester_sec">
                                    <div className="table-responsive">
                                      <table className="table table-hover ">
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              style={{ width: "250px" }}
                                            >
                                              Subject
                                            </th>
                                            <th scope="col">Max Marks</th>
                                            <th scope="col">Obtain Marks</th>
                                            <th scope="col">Project Marks</th>
                                            <th scope="col">Total Marks</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Compiler Design"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="70"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="25"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="95"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Advanced Database Management System"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="60"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="22"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="82"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Big Data analytics"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="54"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="32"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="86"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Lab Based on DE"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="60"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="36"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="96"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Compiler Design Lab"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="61"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="37"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="98"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h3>Total</h3>
                                              {/* <input type="text" placeholder="Total" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>500</h4>
                                              {/* <input type="text" placeholder="500" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>305</h4>
                                              {/* <input type="text" placeholder="305" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>152</h4>
                                              {/* <input type="text" placeholder="152" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>457</h4>
                                              {/* <input type="text" placeholder="457" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                {/* end other table */}
                                {/* start 3 table */}
                                {/* end 3rd table */}
                                <br /> <br />
                                <label
                                  for="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="ion-android-upload"></i> Upload
                                  Provisoinal Certificate
                                </label>
                                <input id="file-upload" type="file" />
                                <label
                                  for="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="ion-android-upload"></i> Upload
                                  Master Degree
                                </label>
                                <input id="file-upload" type="file" />
                                <label
                                  for="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="ion-android-upload"></i> Upload
                                  Bachelor Degree
                                </label>
                                <input id="file-upload" type="file" />
                                <br />
                              </form>
                            </div>
                          </div>
                        </div>

                        {/* school */}
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            role="tab"
                            id="ingredient"
                          >
                            <h4 className="panel-title colge_img">
                              <a
                                href="/#"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                className="collapsed"
                              >
                                <img
                                  src="../images/jgi-logo.png"
                                  className="img-fluid"
                                />
                              </a>
                            </h4>
                            <div className="user_clg_dtl">
                              <h3>JGI Group</h3>
                              <p>
                                Bachelor's Degree, Computer Science
                                <br />
                                2011 - 2015
                              </p>
                            </div>
                          </div>
                          <div
                            id="collapseTwo"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="ingredient"
                          >
                            <div className="bg-image">
                              <div className="marksheet_header text-center">
                                <img
                                  src="../images/jgi-logo.png"
                                  className="img-fluid"
                                  alt="galgotia seven"
                                />
                                <div className="tag_head">
                                  <h1>JGI Group</h1>
                                  <h4>
                                    ( Deemed to Be University ) <br />
                                    (Accredited by NAAC with Grade 'A')
                                  </h4>
                                </div>
                              </div>
                              <br />

                              <form
                                onSubmit={this.handleFormSubmit}
                                className="profile_box"
                              >
                                <div className="student_detail_sec">
                                  <div className="name_sec pull-left">
                                    <h3>
                                      Student's Name :{" "}
                                      {this.state.newUser.first_name}{" "}
                                      {this.state.newUser.last_name}
                                      <br />
                                      <br />
                                      Enroll No. : 2019-303-403
                                    </h3>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2160;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester2"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester2"
                                >
                                  Semester <span>&#x2161;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester3"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester3"
                                >
                                  Semester <span>&#x2162;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2163;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2164;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2165;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2166;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2167;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2168;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  Semester <span>&#x2169;</span>
                                </button>
                                <button
                                  className="btn btn-primary custom-file-upload2"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseSemester"
                                  aria-expanded="false"
                                  aria-controls="collapseSemester"
                                >
                                  <i className="ion-plus"></i>
                                </button>
                                <div
                                  className="collapse1"
                                  id="collapseSemester"
                                >
                                  <div className="card card-body semester_sec">
                                    <div className="table-responsive">
                                      <table className="table table-hover ">
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              style={{ width: "250px" }}
                                            >
                                              Subject
                                            </th>
                                            <th scope="col">Max Marks</th>
                                            <th scope="col">Obtain Marks</th>
                                            <th scope="col">Project Marks</th>
                                            <th scope="col">Total Marks</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Compiler Design"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="70"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="25"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="95"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Advanced Database Management System"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="60"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="22"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="82"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Big Data analytics"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="54"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="32"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="86"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Lab Based on DE"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="60"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="36"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="96"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="Compiler Design Lab"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="100"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="61"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="37"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <input
                                                type="text"
                                                placeholder="98"
                                              />
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <p
                                                data-placement="top"
                                                title="Edit"
                                                className="icon_sec1"
                                              >
                                                <button className="btn btn-success btn-xs">
                                                  <i className="ion-compose"></i>
                                                </button>
                                              </p>
                                              <p
                                                data-placement="top"
                                                title="Delete"
                                                className="icon_sec2"
                                              >
                                                <button className="btn btn-danger btn-xs">
                                                  <i className="ion-trash-a"></i>
                                                </button>
                                              </p>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h3>Total</h3>
                                              {/* <input type="text" placeholder="Total" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>500</h4>
                                              {/* <input type="text" placeholder="500" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>305</h4>
                                              {/* <input type="text" placeholder="305" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>152</h4>
                                              {/* <input type="text" placeholder="152" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr">
                                              <h4>457</h4>
                                              {/* <input type="text" placeholder="457" /> */}
                                            </td>
                                            <td className="profile_input profile_wrap_cntr"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                {/* end other table */}
                                {/* start 3 table */}
                                {/* end 3rd table */}
                                <br /> <br />
                                <label
                                  for="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="ion-android-upload"></i> Upload
                                  Provisional Certificate
                                </label>
                                <input id="file-upload" type="file" />
                                <label
                                  for="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="ion-android-upload"></i> Upload
                                  Master Degree
                                </label>
                                <input id="file-upload" type="file" />
                                <label
                                  for="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="ion-android-upload"></i> Upload
                                  Bachelor Degree
                                </label>
                                <input id="file-upload" type="file" />
                                <br />
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* end school */}
                        {/* certificate */}

                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            role="tab"
                            id="ingredient"
                          >
                            <h4 className="panel-title colge_img">
                              <a
                                href="/#"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#collapseThree"
                                className="collapsed"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <img
                                  src="../images/certi.png"
                                  className="img-fluid"
                                />
                              </a>
                            </h4>
                            <div className="user_clg_dtl_certi">
                              <h3>Certification</h3>
                            </div>
                          </div>
                          <div
                            id="collapseThree"
                            className="panel-collapse collapse certi_detail_page"
                            role="tabpanel"
                            aria-labelledby="ingredient"
                          >
                            <div className="panel panel-default">
                              <div className="panel-heading" role="tab">
                                <h4 className="panel-title colge_img">
                                  <img
                                    src="../images/ibm2.png"
                                    className="img-fluid"
                                    alt="glagotia ten"
                                  />
                                </h4>
                                <div className="user_clg_dtl">
                                  <h3>IBM - System Analyst</h3>
                                  <p>
                                    IBM
                                    <br />
                                    Issued Jun 2018 No Expiration Date
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div className="panel-heading" role="tab">
                                <h4 className="panel-title colge_img">
                                  <img
                                    src="../images/course_logo.png"
                                    className="img-fluid"
                                    alt="galgotia eleven"
                                  />
                                </h4>
                                <div className="user_clg_dtl">
                                  <h3>Fundamental Of AI</h3>
                                  <p>
                                    Qversity
                                    <br />
                                    Issued Jun 2019 No Expiration Date
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div className="panel-heading" role="tab">
                                <h4 className="panel-title colge_img">
                                  <img
                                    src="../images/cisco.png"
                                    className="img-fluid"
                                    alt="galgotia fifteen"
                                  />
                                </h4>
                                <div className="user_clg_dtl">
                                  <h3>Cisco - Networking</h3>
                                  <p>
                                    Cisco
                                    <br />
                                    Issued Jun 2013 No Expiration Date
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* end certificate */}
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
            {/* end detail */}
            <div
              className="white_bg_cntr clearfix profile_update"
              style={{ display: "none" }}
              id={"change_password"}
            >
              {this.state.errPassword ? (
                <div className="alert alert-error" id="passwordInfo">
                  {this.state.errPasswordMess}
                </div>
              ) : (
                ""
              )}
              {this.state.succPassword ? (
                <div className="alert alert-success" id="passwordInfo">
                  {this.state.succPasswordMess}
                </div>
              ) : (
                ""
              )}
              <div className="profile_box account_box">
                <div className="sectionHeading">Change Password</div>
                <div className="profile-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Current Password
                        </label>
                        <input
                          type="password"
                          id="inputConfirmCurrentPassword"
                          className="form-control old_password"
                          required="required"
                          name={"old_password"}
                          value={this.state.change_password.old_password}
                          onChange={this.handlePasswordInput.bind(this)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          New Password
                        </label>

                        <input
                          type="password"
                          id="inputPassword"
                          className="form-control password"
                          required="required"
                          name={"password"}
                          value={this.state.change_password.password}
                          onChange={this.handlePasswordInput.bind(this)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Confirm New Password
                        </label>

                        <input
                          type="password"
                          id="inputConfirmPassword"
                          className="form-control password_confirmation"
                          required="required"
                          name={"password_confirmation"}
                          value={
                            this.state.change_password.password_confirmation
                          }
                          onChange={this.handlePasswordInput.bind(this)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 button-group">
                      <input
                        className="save_button"
                        onClick={this.handlePasswordFormSubmit.bind(this)}
                        type="submit"
                        value="Change Password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="white_bg_cntr clearfix profile_update"
              style={{ display: "none" }}
              id={"additional_info"}
            >
              {/* <section className="content equal-cols container-fluid"> */}
              {/* <div className="inner_content_cntr white_bg_cntr"> */}
              {this.state.succAdditional ? (
                <div className="alert alert-success" id="passwordInfo">
                  {this.state.succAdditionalMess}
                </div>
              ) : (
                ""
              )}
              <div className="profile_box account_box">
                <div class="sectionHeading">Additional Information</div>
                <div className="profile-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="profile_select profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Select Your Profession{" "}
                        </label>

                        <select
                          className={"professtion_type"}
                          name="professtion_type"
                          id="professtion_type"
                          onChange={this.handleAdditionalInfo}
                          value={
                            this.state.additional_info.professtion_type
                              ? this.state.additional_info.professtion_type
                              : ""
                          }
                        >
                          <option value="">Select Your Profession</option>
                          {(() => {
                            if (Object.keys(professionList).length !== 0) {
                              //let ucClassList = professionList.upcomming_class;

                              professionList.map((dataObj, counter) => {
                                let selected =
                                  dataObj.id ===
                                  this.state.additional_info.professtion_type
                                    ? "selected"
                                    : "";
                                rows.push(
                                  <option
                                    key={counter}
                                    value={dataObj.id}
                                    selected={
                                      this.state.additional_info
                                        .professtion_type === dataObj.id
                                    }
                                  >
                                    {dataObj.name}
                                  </option>
                                );
                              });
                            }
                          })()}
                          {rows}
                        </select>
                        <span style={{ color: "red" }} className="customAlert">
                          {this.state.errors["professtion_type"]}
                        </span>
                      </div>
                      {/* <span style={{ color: "red" }} className="alert">
                          {this.state.errors["professtion_type"]}
                        </span> */}
                    </div>

                    <div className="col-lg-4">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Organisation / College Name
                        </label>
                        <input
                          type="text"
                          className="form-control organisation"
                          id="organisation"
                          className={"organisation"}
                          name={"organisation"}
                          value={
                            this.state.additional_info.organisation
                              ? this.state.additional_info.organisation
                              : ""
                          }
                          onChange={this.handleAdditionalInfo}
                        />
                        <span style={{ color: "red" }} className="customAlert">
                          {this.state.errors["organisation"]}
                        </span>
                      </div>
                      {/* <span style={{ color: "red" }} className="alert">
                          {this.state.errors["organisation"]}
                        </span> */}
                    </div>

                    <div className="col-lg-12">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Your Address{" "}
                        </label>

                        <textarea
                          row="3"
                          className={`form-control address_line address_line`}
                          id="address_line"
                          placeholder="Street Address"
                          name={"address_line"}
                          value={this.state.additional_info.address_line}
                          onChange={this.handleAdditionalInfo}
                        ></textarea>
                        <span style={{ color: "red" }} className="customAlert">
                          {this.state.errors["address_line"]}
                        </span>
                      </div>
                      {/* <span style={{ color: "red" }} className="alert">
                          {this.state.errors["address_line"]}
                        </span> */}
                    </div>

                    <div className="col-lg-6">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Your Linkedin Profile URL
                        </label>
                        <div className="socialLink-box">
                          <input
                            type="text"
                            className="form-control linkedin_profile"
                            id="street_address"
                            name={"linkedin_profile"}
                            value={
                              this.state.additional_info.linkedin_profile
                                ? this.state.additional_info.linkedin_profile
                                : ""
                            }
                            onChange={this.handleAdditionalInfo}
                          />

                          <span className="socialLinkImg">
                            <img
                              src="dist/img/linkedin-icon.png"
                              className="social_icon_fix"
                            />
                          </span>
                        </div>

                        {/*<span style={{ color: "red" }} className="alert">{this.state.errors["address_line"]}</span>*/}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile_input profile_wrap_cntr form-group">
                        <label className="profile-top-label">
                          Your Facebook Profile URL
                        </label>

                        <div className="socialLink-box">
                          <input
                            type="text"
                            className="form-control facebook_profile"
                            id="street_address"
                            name={"facebook_profile"}
                            value={
                              this.state.additional_info.facebook_profile
                                ? this.state.additional_info.facebook_profile
                                : ""
                            }
                            onChange={this.handleAdditionalInfo}
                          />
                          <span className="socialLinkImg">
                            <img
                              src="dist/img/facebook-icon.png"
                              className="social_icon_fix"
                            />
                          </span>
                        </div>
                        {/*<span style={{ color: "red" }} className="alert">{this.state.errors["address_line"]}</span>*/}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 button-group">
                      <input
                        className="save_button"
                        onClick={this.handleAdditionalFormSubmit.bind(this)}
                        type="submit"
                        value="Save All Change"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* </div>
                        </section> */}
            </div>

            <div id="verifyotp" className="modal in" role="dialog">
              <div className="modal-dialog model-signup">
                {/* <!-- Modal content--> */}
                <div className="modal-content clearfix">
                  <div className="modal-header">
                    <h4 className="modal-title forget-title">Verify OTP</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={this.closeNotifyModel.bind(this)}
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>

                  <span style={{ color: "red" }} id="otpInfo">
                    {" "}
                    {this.state.otpError ? this.state.otpsuccessMsg : ""}
                  </span>
                  <div className="modal-body">
                    <div className="signup-form">
                      <div className="col-md-12 col-sm-12 col-x-12">
                        <p>An OTP has been sent to provided mobile number. </p>
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <img src="" alt="otp" />
                            </span>
                            <input
                              type={"text"}
                              className={"form-control noerror otp_field"}
                              title={"OTP"}
                              key={"otp"}
                              name={"email"}
                              id={"otp"}
                              value={this.state.otpValue}
                              placeholder={"otp"}
                              onChange={(e) =>
                                this.setState({
                                  otpValue: e.target.value,
                                  otpError: false,
                                })
                              }
                            />
                          </div>
                          {/* <div className="email alert">{this.state.errors["email"]}</div> */}
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 col-x-12">
                        <span style={{ fontSize: "15px" }}>
                          {this.state.time.s !== 0
                            ? `waiting ${this.state.time.s} seconds ..`
                            : ""}
                        </span>
                        <div className="row btns-box">
                          {this.state.resendButton ? (
                            <button
                              onClick={this.sendOtp.bind(this)}
                              className="save_button"
                            >
                              Resend OTP
                            </button>
                          ) : (
                            ""
                          )}
                          <button
                            // onClick={this.matchOtp.bind(this)}
                            className="save_button"
                          >
                            Verify OTP
                          </button>

                          {/*Submit */}
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Modal Content --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state,
    userUpdateData: state.user.get("msg"),
    userProfileData: state.user.get("userProfileResult"),
    professionListData: state.user.get("professionListResult"),
    getMobileOtpData: state.user.get("getMobileOtpData"),
  }),
  { ...UserActions }
)(SignupFormContainer);
