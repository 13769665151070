import React, { Component } from "react";
import { connect } from "react-redux";

import AuthAction from "../../redux/auth/actions";
import axios from "axios";
import { userLogin, header } from "../../config";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      formIsValid: true,
      errorMessage: "",
      mailConfirm: false,
      password: "",
      password_confirmation: "",
      isLoaderVisible: "none",
      isValidToken: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleValidate = () => {
    let errors = {};
    const { password, password_confirmation } = this.state;

    let formIsValid = true;
    this.setState({ errors });
    if (password == "") {
      formIsValid = false;
      errors["password"] = "password";
    } else if (password_confirmation == "") {
      formIsValid = false;
      errors["password_confirmation"] = "password_confirmation";
    }
    this.setState({ formIsValid });
    return formIsValid;
  };

  componentDidMount() {
    this.checkTokenValidity();
  }

  checkTokenValidity = () => {
    axios
      .get(`${userLogin.verifyToken}/${this.props.match.params.token}`, {
        headers: header,
      })
      .then((res) => {
        if (res.data.status_code == 200) {
          this.setState({ isValidToken: true });
        } else if (res.data.status_code == 404) {
          this.setState({
            isValidToken: false,
            errorMessage: res.data.message,
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { password, password_confirmation, isValidToken } = this.state;
    if (this.handleValidate()) {
      if (isValidToken) {
        localStorage.setItem("resettoken", this.props.match.params.token);
        const { resetPassword } = this.props;
        resetPassword({ password, password_confirmation });
        this.setState({ isLoaderVisible: "block" });
      } else {
        this.setState({ errorMessage: "User token is not found" });
        setTimeout(() => {
          this.setState({
            errorMessage: "",
            password: "",
            password_confirmation: "",
          });
        }, 4000);
      }
    }
  };

  componentDidUpdate(previousProps) {
    if (previousProps.isResetdataSuccess != this.props.isResetdataSuccess) {
      if (this.props.isResetdataSuccess != null) {
        if (
          this.props.isResetdataSuccess &&
          this.props.isResetdataSuccess.status_code == 200
        ) {
          this.setState({
            mailConfirm: true,
            isLoaderVisible: "none",
            password: "",
            password_confirmation: "",
          });
        } else if (
          this.props.isResetdataSuccess &&
          this.props.isResetdataSuccess.status_code &&
          this.props.isResetdataSuccess.status_code == 404
        ) {
          this.setState({
            mailConfirm: false,
            errorMessage: this.props.isResetdataSuccess.message,
            isLoaderVisible: "none",
            password: "",
            password_confirmation: "",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        } else if (
          this.props.isResetdataSuccess &&
          this.props.isResetdataSuccess.response &&
          this.props.isResetdataSuccess.response.status == 422
        ) {
          this.setState({
            mailConfirm: false,
            errorMessage: this.props.isResetdataSuccess.response.data.errors
              .password[0],
            password: "",
            password_confirmation: "",
            isLoaderVisible: "none",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      }
    }
  }

  handleProceed = () => {
    this.props.history.push("/");
  };

  render() {
    const {
      mailConfirm,
      errorMessage,
      errors,
      formIsValid,
      password_confirmation,
      password,
      isLoaderVisible,
    } = this.state;
    return (
      <>
        <header>
          <nav
            class="navbar navbar-expand-md custom-navbar login-bg"
            id="headerdiv"
          >
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img src="/dist/img/logo.png" class="img-fluid" alt="Logo" />
              </a>
            </div>
          </nav>
        </header>
        {/* modal loader */}
        <div className="modal" style={{ display: isLoaderVisible }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body loaderBox" style={{ margin: "auto" }}>
                <h3>Please wait...</h3>
                <div className="loader"></div>
              </div>
            </div>
          </div>
        </div>
        {/* loader ends */}
        {/* Confimation Modal */}
        {mailConfirm && (
          <div className="modal" style={{ display: "block" }} id="modalId">
            <div className="modal-dialog confirm-dialog modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ mailConfirm: false })}
                  >
                    &times;
                  </button>
                  <div className="row">
                    <div className="col-md-12 confirm-content">
                      <h2>Confirmation !</h2>
                      <p>Your Password Reset Successfully !</p>
                    </div>
                    <div className="col-md-12 text-center btn-group-b">
                      <button
                        type="button"
                        className="btn-ok"
                        data-dismiss="modal"
                        onClick={this.handleProceed}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Modal Ends */}
        <div class="login-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div class="login-box">
                  <div class="leftSec">
                    <img
                      src="/dist/img/reset-password.png"
                      class="img-fluid"
                      alt="login Image"
                    />
                  </div>
                  <div class="rightSec">
                    <form onSubmit={this.handleSubmit}>
                      <h2>Reset Password</h2>
                      <span className="error-msg">{errorMessage}</span>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>Password</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.password == "password" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i class="fas fa-envelope"></i>
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                value={password}
                                name="password"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.password_confirmation ==
                                  "password_confirmation" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i class="fas fa-envelope"></i>
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                value={password_confirmation}
                                name="password_confirmation"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <button class="btn-login" onClick={this.handleSubmit}>
                            Submit
                          </button>
                          <button
                            class="btn-back"
                            onClick={() => this.props.history.push("/")}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    state,
    isResetdataSuccess: state.Auth.resetdata,
  }),
  { ...AuthAction }
)(ResetPassword);
