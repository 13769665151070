import { siteUrl } from "./siteConfig";

let site_url = siteUrl.site_url;
let domain = siteUrl.site_url + "api/";

let user_token = localStorage.getItem("u$tOken");

let TOKEN_STRING = user_token;
const today = new Date();

const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PUT",
  
  "X-Authorization":
    "USp7DbDxHQ9rDSvhmyhz1ubZd4ZrlnoIiGmc70jlSkcVG4h8GiDSCgj6WsADhNmF",
  Authorization: TOKEN_STRING,
};

const recaptca = {
  RECAPTCHA_SITEKEY: "6LeLnscZAAAAAEbho2rNSwFCwWwsSygG_-xsXno1",
};

const dashboard = {
  enabled: true,
  userCourses: domain + "user-courses",
  instructorCourses: domain + "instructor-courses",
};

const user = {
  userProfileUpdate: domain + "updateuser",
  userProfileGetAPIUrl: domain + "profile",
  getUserNotification: domain + "get-student-notification",
  getUserSeminar: domain + "seminarList",
  fetchProfession: domain + "professions",
  sendOtp: domain + "getMobileOtp",
  matchOtp: domain + "matchMobileOtp",
  userCertificate: domain + "user-certificate",

  userAdditionalInfo: domain + "update-additional-info",
  userUpdatePassword: domain + "update-password",
  pendingTask: domain + "pending_task",
};

const compilerSection = {
  compilerSections: domain + "compilerSections",
};
const userLogin = {
  login: domain + "login",
  compilerUserRegistration: domain + "compilerUserRegistration",
  forgotPassword: domain + "forgotPassword",
  resetPassword: domain + "reset-password",
  verifyToken: domain + "verifyToken",
};
const message = {
  fetchInstructors: domain + "getProfessiorStudent",

  fetchUserMessages: domain + "getMessage",
  sendUserMessages: domain + "send_message",
  studentList: domain + "courses-student-list",
  fetchCoursesProfessors: domain + "user-courses-professors",
  studentInstrcutors: domain + "studentInstrcutors",
  insertMessage: domain + "insertMessage",
  messages: domain + "messages",
};

const createMeeting = {
  zoomMeetingSlot: domain + "zoomMeetingSlot",
  addBatchZoomMeeting: domain + "addBatchZoomMeeting",
};

const course = {
  courseDetail: domain + "course-by-slug",
  entityComplete: domain + "mark-as-completed",
  uploadimage: domain + "uploadimage",
  getUpcommingClasses: domain + "get-upcomming-classes",
  sectionUnitProgress: domain + "sectionUnitProgress",
  addUnit: domain + "add-unit",
  assignments: domain + "assignments",
  addAssignment: domain + "addAssignment",
  assignmentDetails: domain + "assignmentDetails",
  submitAssignment: domain + "submitAssignment",
  creataeHtmlFile: domain + "creataeHtmlFile",
  login: domain + "login",
};

const attendance = {
  getAttendanceData: domain + "get-attendance-data",
};

const teacherUnitDetails = {
  getUnit: domain + "get-unit",
  getAllSectionUnit: domain + "get-all-section-unit",
  getAllBatch: domain + "get-all-batch-of-course",
  getAllHostId: domain + "getAllHostId",
  quizes: domain + "quizes",
  addSection: domain + "add-section",
  sendStudentNotification: domain + "send-student-notification",
  language: domain + "language",
};

const assignment = {
  assignments: domain + "assignments",
  assignmentDetails: domain + "assignmentDetails",
  verifyAssignment: domain + "verifyAssignment",
  reviewAssignment: domain + "reviewAssignment",
  mySchedule: domain + "mySchedule",
};

const onlineUser = {
  fetchBatchUsingWithStatus: domain + "fetchBatchUsingWithStatus",
  userStatus: domain + "userStatus",
  getAllStudentByInstructor: domain + "getAllStudentByInstructor",
};
const onlinecompiler = {
  onlinecompiler: domain + "onlineComplie",
};

const common = {
  fetchProfession: domain + "getProfession",
};

const quiz = {
  startQuiz: domain + "quiz-start",
  quizResult: domain + "quiz-result",
  nextQuestion: domain + "quiz-next-question",
  prevQuestion: domain + "quiz-prev-question",
  submitAnswer: domain + "quiz-assessment-attempt",
  endQuiz: domain + "quiz-complete",
  quizes: domain + "quizes",
  notAssignedQuiz: domain + "notAssignedQuiz",
  questions: domain + "questions",
  quizeDetails: domain + "quizeDetails",
  quizStudents: domain + "quizStudents",
  studentAssessmentDetails: domain + "studentAssessmentDetails",
  quizQuestion: domain + "quizQuestion",
  reviewQuiz: domain + "reviewQuiz",
  createQuiz: domain + "createQuiz",
  getInstructorQuiz: domain + "getInstructorQuiz",
  importQuizQuestions: domain + "importQuizQuestions",
};

const search = {
  allCourse: domain + "courses/category",
  searchUrl: domain + "courses/course_search",
};

const meeting = {
  meetingAttendance: domain + "attendance",
};
const ForumApiURL = {
  createForum: domain + "createForum",
  fetchForum: domain + "getAllForums",
  fetchForumById: domain + "getForumById",
  createForumThread: domain + "insertForumThread",
};
const liveclassActivate = {
  beforestarttime: 5 /*(in minutes) Live Class*/,
  beforeEndTime: 5,
  afterendtime: 5 /*(in minutes) Live Class*/,

  beforestarttimeclasses: 30 /* Upcomming Class dashboard*/,
  showLiveLinkAfterTime: 120 /*(in minutes) Upcommig Class dashboard*/,
};
const Auth0Config = {};
const messages = {
  instructorsBatchesStudents: domain + "instructorsBatchesStudents",
  composeMessage: domain + "composeMessage",
  messageList: domain + "messageList",
  replyMessage: domain + "replyMessage",
  getAllMessage: domain + "getAllMessage",
  getAllTeachers: domain + "getAllTeachers",
};

const polls = {
  PollReport: domain + "Poll/PollReport",
  CreatePoll: domain + "Poll/CreatePoll",
  GetZoomMeeting: domain + "Poll/GetZoomMeeting",
  GetPollDetail: domain + "Poll/GetPollDetail",
};

export {
  dashboard,
  header,
  user,
  course,
  site_url,
  user_token,
  ForumApiURL,
  message,
  common,
  onlineUser,
  meeting,
  teacherUnitDetails,
  liveclassActivate,
  search,
  onlinecompiler,
  createMeeting,
  messages,
  polls,
  assignment,
  attendance,
  quiz,
  Auth0Config,
  userLogin,
  compilerSection,
  recaptca,
};
