const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  SIGNUP: "SIGNUP",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  resetPassword: (emailId) => ({
    type: actions.RESET_PASSWORD,
    payload: { emailId },
  }),
  resetPasswordSuccess: (result) => ({
    type: actions.RESET_PASSWORD_SUCCESS,
    payload: { result },
  }),

  login: (userData) => ({
    type: actions.LOGIN_REQUEST,
    payload: { userData },
  }),

  loginSuccess: (result) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { result },
  }),

  forgotpassword: (emailId) => ({
    type: actions.FORGOT_PASSWORD,
    payload: { emailId },
  }),

  forgotpasswordsuccess: (result) => ({
    type: actions.FORGOT_PASSWORD_SUCCESS,
    payload: { result },
  }),

  logout: () => ({
    type: actions.LOGOUT,
  }),

  UserSignup: (data) => ({
    type: actions.SIGNUP,
    payload: { data },
  }),

  signUpSuccess: (result) => ({
    type: actions.SIGNUP_SUCCESS,
    payload: { result },
  }),
};

export default actions;
