import React, { Component } from "react";
import { course, header, quiz } from "../../config";
import htmlParser from "react-html-parser";

import $ from "jquery";
import { Link } from "react-router-dom";

import axios from "axios";
/* import { Offline, Online } from "react-detect-offline"; */

export default class QuizView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Quiz: {},
      finishQuiz: false,
      quizStart: false,
      quizDetails: {},
      curentQuestionNo: 1,
      firstQuestion: false,
      lastQuestion: false,
      submittedAnswer: "",
      answer: "",
      submitted: false,
      user_answer: "",
      checked: false,
      user_attempt_no: "",
      attempt_exceeded: false,
      user_previous_results: "",
      questionLoading: false,
      editorState: "",
      expiredMessage: "",
      isStarted: false,
      wrongAnswerMsg: "",
      correctAnswerIs: "",
      show_answer: 0,
      optionError:0,
    };
    this.dataObject = {};
    this.quiz_result = [];
    this.obj = {};
    this.state = {
      time: {},
      seconds: 0,
      offline: false,
      online: true,
      total_questions: 0,
      obtain_marks: "",
      total_marks: "",
      selectedFile: null,
    };
    this.timer = 0;
    this.Inputtext = React.createRef();
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleQuizSubmission = this.handleQuizSubmission.bind(this);
  }

  onEditorStateChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.Inputtext.current.focus();
  };

  // onFileChange = (event) => {
  //   event.preventDefault();
  //   this.setState({ selectedFile: event.target.files[0] });
  // };

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };

    return obj;
  }

  componentDidMount() {
    var self = this;
    window.addEventListener("offline", function(e) {
      self.setState({ offline: true });
      self.setState({ online: false });
      $("#quizSubmit").css("display", "none");
    });
    window.addEventListener("online", function(e) {
      self.setState({ offline: false });
      self.setState({ online: true });
      if (self.state.finishQuiz == false) {
        $("#quizSubmit").css("display", "block");
      }
    });
    this.getQuestionList();
  }

  getQuestionList = () => {
    let quizId = this.props.quizId;
    let data = { quiz_id: quizId };

    let data1 = { quiz_id: quizId };
    var milliseconds = new Date().getTime();
    const startQuestion = quiz.nextQuestion;
    var token = localStorage.getItem("u$tOken");
    fetch(`${startQuestion}?c=${milliseconds}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data1),
    })
      .then(function(response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server.Refresh your page");
        }
        return response.json();
      })
      .then((res) => {
        if (res.status_code == 200) {
          const data = {
            data: [res.data],
          };
          if (data.data !== undefined) {
            this.setState({ total_questions: res.total_questions });
            

            if (res.question_no == res.total_questions) {
              this.setState({ lastQuestion: true });
            }
            if (res.question_no == 1) {
              this.setState({ firstQuestion: true });
            }
            this.setState({
              quizDetails: data.data,
              curentQuestionNo: res.question_no,
              answer: undefined,
              optionError: 0
            });
          }

          this.setState({ user_attempt_no: res.user_attempt_no });
          // if (
          //   this.props.filteredData.quiz_detail[0].type == 2 &&
          //   this.props.filteredData.quiz_detail[0].quiz_type == "Practice"
          // ) {
          //   debugger;
          //   this.setState({ quizStart: true, finishQuiz: false });
          //   this.setState({ seconds: res.quiztimer.duration_in_seconds });
          //   $("#quizSubmit").css("display", "block");

          //   this.timer = setInterval(this.countDown, 1000);
          // } else {
          if (this.props.quizRetake && this.props.quizRetake == 0) {
            this.props.quizRetake = 1;
          }

          if (res.user_attempt_no > this.props.quizRetake) {
            this.setState({ attempt_exceeded: true });
            this.setState({ user_previous_results: res.previousResults });
          } else {
            if (
              this.props.filteredData.quiz_detail[0].type == 2 &&
              this.props.filteredData.quiz_detail[0].quiz_type == "Practice"
            ) {
              this.setState({ quizStart: false, finishQuiz: false });
              this.setState({
                user_previous_results: res.previousResults,
              });
            } else if (res.quiztimer.already_start == "finished") {
              this.setState({ quizStart: true, finishQuiz: true });
              this.setState({
                obtain_marks: res.quizResult.obtain_marks,
                total_marks: res.quizResult.total_marks,
              });
            } else if (res.quiztimer.already_start == true) {
              this.setState({ quizStart: true, finishQuiz: false });
              this.setState({
                seconds: res.quiztimer.duration_in_seconds,
              });
              $("#quizSubmit").css("display", "block");

              this.timer = setInterval(this.countDown, 1000);
            } else {
              this.setState({ quizStart: false, finishQuiz: false });
              this.setState({
                user_previous_results: res.previousResults,
              });
            }
            // }
          }
        }
      });
  };

  startTimer() {
    let quizId = this.props.quizId;
    let data = { quiz_id: quizId, user_attempt_no: this.state.user_attempt_no };
    var token = localStorage.getItem("u$tOken");
    var milliseconds = new Date().getTime();
    const startQuiz = quiz.startQuiz;
    fetch(`${startQuiz}?c=${milliseconds}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("u$tOken"),
      },
      body: JSON.stringify(data),
    })
      .then(function(response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server.Refresh your page");
        }
        return response.json();
      })

      .then((res) => {
        if (res.status_code == 404) {
          this.setState({
            expiredMessage:
              "Quiz Is Not Accessable, It Will Be Accessable At The Time Starting.",
            isStarted: true,
          });
        } else if (res.status_code == 200) {
          if (
            this.props.filteredData.quiz_detail[0].type != 2 &&
            res.data.already_start == "finished"
          ) {
            this.setState({
              quizStart: true,
              finishQuiz: true,
              isStarted: false,
            });
            this.setState({
              obtain_marks:
                res.data.quizResult && res.data.quizResult.obtain_marks,
              total_marks:
                res.data.quizResult && res.data.quizResult.total_marks,
            });
            $("#quizSubmit").css("display", "none");
          } else {
            this.setState({ seconds: res.data.duration_in_seconds });
            $("#quizSubmit").css("display", "block");
            this.timer = setInterval(this.countDown, 1000);
          }
        }
      });

    this.setState({ quizStart: true });
    if (this.timer == 0 && this.state.seconds > 0) {
      // document.cookie = 'RemainTime' + "=" + this.state.seconds;
      /* Show Questions */
      $("#quizSubmit").css("display", "block");
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    // localStorage.setItem('timer',seconds)
    const course_id = localStorage.getItem("course_id");
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (seconds == 0) {
      const quizResult = quiz.quizResult;
      let quizId = this.props.quizId;
      const endQuiz = quiz.endQuiz;

      // $('#quizSubmit').css('display','none')
      clearInterval(this.timer);
      this.setState({ quizDetails: "" });
      // this.submitQuiz()
      var milliseconds = new Date().getTime();
      fetch(`${endQuiz}?c=${milliseconds}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("u$tOken"),
        },
        body: JSON.stringify({
          quiz_id: quizId,
          user_attempt_no: this.state.user_attempt_no,
        }),
      }).then((res) => {
        // this.setState({quizStart : true,finishQuiz:true})

        fetch(`${quizResult}?c=${milliseconds}`, {
          method: "post",
          headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("u$tOken"),
        },
          body: JSON.stringify({
            quiz_id: quizId,
            user_attempt_no: this.state.user_attempt_no,
          }),
        })
          .then(function(response) {
            if (response.status >= 400) {
              throw new Error("Bad response from server.Refresh your page");
            }
            return response.json();
          })
          .then((res) => {
            this.setState({
              obtain_marks: res.data.obtain_marks,
              total_marks: res.data.total_marks,
            });
          });
        this.setState({ submitted: true });
        this.props.completeEntity(this.props.filteredData.id, course_id);

        // this.setState({submitted : true})
      });
    }
  }

  handleQuizSubmission = (e) => {
    let value = e.target.value;
    this.setState({ answer: value });
  };

  // handleQuizSubmission = (e) => {
  //
  //     let quiz_id = $('input[name=quiz_id]').val();
  //     this.dataObject['quiz_id'] = quiz_id;
  //
  //     this.obj['quiz_id'] = quiz_id
  //     let value = e.target.value;
  //     let name = e.target.name;
  //     let index = this.quiz_result.map(x => {
  //         return x.question_id;
  //     }).indexOf(name);
  //     if(index !== -1) {
  //         this.quiz_result.splice(index, 1);}
  //
  //     this.quiz_result.push({ 'question_id' : name, 'answer' : value})
  //
  //     this.dataObject['quiz_result'] = this.quiz_result

  //     let data = this.dataObject;
  //     // console.log('Abhishek',data)
  //     this.setState(prevState => ({
  //             Quiz: {...prevState.Quiz,data}
  //         }), () => console.log(this.state.Quiz)
  //     )
  //
  // }

  prevQuestion = (e) => {
    e.preventDefault();
    const quesId = e.currentTarget.dataset.id;
    const quizId = e.currentTarget.dataset.quizid;
    this.setState({ optionError: 0 });
    let data = {
      quiz_id: quizId,
      ques_id: quesId,
      ques_count: this.state.curentQuestionNo,
      user_attempt_no: this.state.user_attempt_no,
      description: this.state.editorState,
      document: this.state.selectedFile,
      type_id: this.state.quizDetails[0].question_details.type_id,
      show_answer: this.state.quizDetails[0].question_details.show_answer,
    };
    const prevQuestion = quiz.prevQuestion;

    const answer = this.state.answer;
    let ansdata = {
      quiz_id: quizId,
      batch_id: "",
      quiz_result: { question_id: quesId, answer: answer },
      user_attempt_no: this.state.user_attempt_no,
      description: this.state.editorState,
      document: this.state.selectedFile,
      type_id: this.state.quizDetails[0].question_details.type_id,
      show_answer: this.state.quizDetails[0].question_details.show_answer,
    };
    var milliseconds = new Date().getTime();
    const submitAnswer = quiz.submitAnswer;
    this.setState({ questionLoading: true, wrongAnswerMsg: "" });
    new Promise((resolve, reject) => {
      fetch(`${submitAnswer}?c=${milliseconds}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("u$tOken"),
        },
        body: JSON.stringify(ansdata),
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    }).then(() => {
      fetch(`${prevQuestion}?c=${milliseconds}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("u$tOken"),
        },
        body: JSON.stringify(data),
      })
        .then(function(response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server.Refresh your page");
          }
          return response.json();
        })
        .then((res) => {
          this.setState({ questionLoading: false });
          const data = {
            data: [res.data],
          };

          if (res.question_no == res.total_questions) {
            this.setState({ lastQuestion: true });
          } else {
            this.setState({ lastQuestion: false });
          }

          if (res.question_no == 1) {
            this.setState({ firstQuestion: true });
          } else {
            this.setState({ firstQuestion: false });
          }
          // this.setState({curentQuestionNo: res.question_no})

          if (data.data !== undefined) {
            this.setState({ quizDetails: data.data });
            this.setState({ curentQuestionNo: res.question_no });
            this.setState({ answer: res.user_answer });
          }
        });
    });
  };
  unitProgress = () => {
    const data = {
      unit_id: this.props.filteredData.id,
      current_page: this.state.curentQuestionNo + 1,
      total_page: this.state.total_questions,
    };
    
    if (data.current_page > data.total_page) {
      data.current_page = data.total_page;
    }
    
    var milliseconds = new Date().getTime();
debugger;
    axios
      .post(`${course.sectionUnitProgress}?c=${milliseconds}`, data, {
        headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("u$tOken"),
		},
      })
      .then((res) => console.log(res));
  };
  nextQuestion = (e) => {
    e.preventDefault();
    const quesId = e.currentTarget.dataset.id;
    const quizId = e.currentTarget.dataset.quizid;
    var token = localStorage.getItem("u$tOken");
    let data = {
      quiz_id: quizId,
      ques_id: quesId,
      ques_count: this.state.curentQuestionNo,
      user_attempt_no: this.state.user_attempt_no,
      description: this.state.editorState,
      document: this.state.selectedFile,
      type_id: this.state.quizDetails[0].question_details.type_id,
    };

    const nextQuestion = quiz.nextQuestion;
    const submitAnswer = quiz.submitAnswer;
    const answer = this.state.answer;

    let ansdata = {
      quiz_id: quizId,
      batch_id: "",
      quiz_result: { question_id: quesId, answer: answer },
      user_attempt_no: this.state.user_attempt_no,
      description: this.state.editorState,
      document: this.state.selectedFile,
      type_id: this.state.quizDetails[0].question_details.type_id,
    };

    this.setState({ questionLoading: true, wrongAnswerMsg: "", answer: undefined, optionError: 0 });
    this.unitProgress();
    var milliseconds = new Date().getTime();
    // console.log(ansdata)
    new Promise((resolve, reject) => {
      fetch(`${submitAnswer}?c=${milliseconds}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("u$tOken"),
        },
        body: JSON.stringify(ansdata),
      })
        .then(() => {
          this.setState({
            questionLoading: false,
            editorState: "",
            selectedFile: null,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    }).then(() => {
      if (!this.state.lastQuestion) {
        fetch(`${nextQuestion}?c=${milliseconds}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(data),
        })
          .then(function(response) {
            if (response.status >= 400) {
              throw new Error("Bad response from server.Refresh your page");
            }
            return response.json();
          })
          .then((res) => {
            this.setState({ questionLoading: false });

            const data = {
              data: [res.data],
            };
            if (res.question_no == res.total_questions) {
              this.setState({ lastQuestion: true });
            }
            if (res.question_no !== 1) {
              this.setState({ firstQuestion: false });
            }
            this.setState({
              quizDetails: data.data,
              curentQuestionNo: res.question_no,
            });
            this.setState({ answer: undefined, optionError:0 });
          });
      }
    });
  };

  submitQuiz = (e) => {
    const quesId = e.currentTarget.dataset.id;
    const quizId = e.currentTarget.dataset.quizid;
    const answer = this.state.answer;
    const submitAnswer = quiz.submitAnswer;
    const endQuiz = quiz.endQuiz;
    const quizResult = quiz.quizResult;
    const course_id = localStorage.getItem("course_id");
    let ansdata = {
      quiz_id: quizId,
      batch_id: "",
      quiz_result: { question_id: quesId, answer: answer },
      user_attempt_no: this.state.user_attempt_no,
      description: this.state.editorState,
      document: this.state.selectedFile,
      type_id: this.state.quizDetails[0].question_details.type_id,
    };
    clearInterval(this.timer);
    var milliseconds = new Date().getTime();
    fetch(`${submitAnswer}?c=${milliseconds}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("u$tOken"),
      },
      body: JSON.stringify(ansdata),
    }).then(() => {
      this.setState({ quizDetails: "" });

      fetch(`${endQuiz}?c=${milliseconds}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("u$tOken"),
        },
        body: JSON.stringify({
          quiz_id: quizId,
          user_attempt_no: this.state.user_attempt_no,
        }),
      }).then((res) => {
        fetch(`${quizResult}?c=${milliseconds}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("u$tOken"),
          },
          body: JSON.stringify({
            quiz_id: quizId,
            user_attempt_no: this.state.user_attempt_no,
          }),
        })
          .then(function(response) {
            if (response.status >= 400) {
              throw new Error("Bad response from server.Refresh your page");
            }
            return response.json();
          })
          .then((res) => {
            this.setState({
              obtain_marks: res.data.obtain_marks,
              total_marks: res.data.total_marks,
            });
          });
        this.setState({ submitted: true });
        this.unitProgress();
        this.props.completeEntity(this.props.filteredData.id, course_id);
        // this.setState({quizStart : true,finishQuiz:true})
      });
    });
  };

 
  handleCheckAnswer = () => {
    
    /*if (this.state.quizDetails[0].question_details.show_answer == 0) {
      this.setState({
        wrongAnswerMsg: "nextQuestion",
        isModalVisible: true,
        correctAnswerIs: 'Answer submitted',
      });
    }else */if (this.state.answer != undefined && this.state.answer  != "") {
      this.setState({ optionError: 0 });
      if (
        this.state.answer == this.state.quizDetails[0].question_details.answer
      ) {
        this.setState({ wrongAnswerMsg: "Correct", isModalVisible: true });
      } else {
        var answerIndex = this.state.quizDetails[0].question_details.answer;
        var correctAnswer = this.state.quizDetails[0].question_details.options[
          answerIndex - 1
        ];


        if (correctAnswer != null && correctAnswer.search("--") > 0 &&
          correctAnswer.search("--!&gt;") < 0 &&
          correctAnswer.search("--&gt;") < 0 &&
          correctAnswer.search("!--") < 0) {
            correctAnswer =  correctAnswer.replace("--", "<br />");;
 
          //correctAnswer = correctAnswer.replaceAll("--", "<br />");
        }
        

        this.setState({
          wrongAnswerMsg: "Incorrect",
          isModalVisible: (this.state.quizDetails[0].question_details.show_answer == 1) ? true : false,
          correctAnswerIs: correctAnswer,
        });
      }
    } else {
      this.setState({ optionError: 1 });
    }
  };
  handleClose = () => {
    this.setState({ isModalVisible: false });
  };
  render() {
    const path = window.location.pathname.replace("/class/", "/course/class/");
    const previousResults = this.state.user_previous_results;

    var milliseconds = new Date().getTime();

    if (this.state.quizDetails == undefined) {
      var checkQuizType = "";
      var filteredData = this.props.filteredData;
      checkQuizType = this.props.filteredData;
      filteredData =
        filteredData.quiz_detail[0] &&
        filteredData.quiz_detail[0].quiz_questions;
    } else {
      var filteredData = this.state.quizDetails;
    }
    // quiz_type: "Practice";
    // type: 2;
    //let isQuisQuestion = filteredData[0].question_detail;

    var valuebutton = "",
      finishbutton = "",
      link = "",
      startbutton = "";

    var attempt_name = [
      "zeroth",
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
    ];
    var result = [];
    if (previousResults !== undefined) {
      Object.keys(previousResults).map(
        function(key) {
          var keyVal = Math.random();
          for (var j = 1; j <= 10; j++) {
            if (j == previousResults[key].user_attempt_no) {
              var attempt = attempt_name[j];
            }
          }
          return result.push(
            <div
              className="attempt_message"
              key={keyVal}
              style={{
                color: "#284272",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              {/* You have got {`${previousResults[key].total_marks}`} marks on your{" "}
              {`${attempt}`} attempt. */}
            </div>
          );
        }.bind(this)
      );
    }

    if (this.state.attempt_exceeded == true) {
      finishbutton = (
        <div>
          <div className={"alert alert-danger"} style={{ fontSize: "15px" }}>
            "Maximum Quiz Attempts” has been exceeded{" "}
          </div>
        </div>
      );
    }

    var questionNotAvailable = "";
    if (filteredData.length == 0 && !this.state.submitted) {
      questionNotAvailable = <h2>&nbsp;</h2>;
    }

    if (this.state.quizStart == true && this.state.finishQuiz == true) {
      finishbutton = (
        <div>
          <div className={"alert alert-success"}></div>
          <div
            style={{ color: "#284272", fontSize: "23px", marginBottom: "10px" }}
          >
            You have got {this.state.obtain_marks} out of{" "}
            {this.state.total_marks}.
          </div>
        </div>
      );
    }

    if (finishbutton !== "") {
      link = (
        <div>
          <div>{result}</div>
          <Link
            to={`/dashboard/c=${milliseconds}`}
            className={"btn btn-blueColor"}
            role="button"
          >
            Back to section
          </Link>
        </div>
      );
    }
    if (this.state.quizStart == false && this.state.finishQuiz == false) {
      startbutton = (
        <div className="col-lg=12 text-center quiz_box">
          <div className="start_quiz_btn">
            <button className={"btn btn-blueColor"} onClick={this.startTimer}>
              Start Quiz
            </button>

            <div className={"max-attempt"}>
              {this.props.filteredData.quiz_detail[0].type == 2 &&
              this.props.filteredData.quiz_detail[0].quiz_type == "Practice" ? (
                ""
              ) : (
                <h3>
                  Quiz Attempt No: {this.state.user_attempt_no}
                  <span>(Max Attempt : {this.props.quizRetake})</span>
                </h3>
              )}
            </div>
          </div>
          <div className="clearfix"></div>
          <br />
          <div>{result}</div>
        </div>
      );
    }

    var startTextMessage = "";
    if (this.state.isStarted) {
      startTextMessage = <h2>{this.state.expiredMessage}</h2>;
    }

    if (
      checkQuizType &&
      checkQuizType.quiz_detail[0].quiz_type == "Practice" &&
      checkQuizType.quiz_detail[0].type == 2
    ) {
      valuebutton = <div className={"quiz_time"}></div>;
    } else if (
      checkQuizType &&
      checkQuizType.quiz_detail[0].quiz_type != "Practice" &&
      checkQuizType.quiz_detail[0].type != 2
    ) {
      // else if (this.state.quizStart == true && this.state.finishQuiz == false) {

      valuebutton = (
        <div className={"quiz_time"}>
          {this.state.time.m} : {this.state.time.s}
        </div>
      );
    }

    return (
      <section className="">
        <div className={"quiz_cntr"}>
          {finishbutton}
          {startbutton}
          {link}
        </div>
        <div className="quiz_accessable">{questionNotAvailable}</div>
        <div className="col-lg-12 quiz_accessable">{startTextMessage}</div>
        {this.state.online ? (
          filteredData && filteredData[0] ? (
            <form id={"quizSubmit"} style={{ display: "none" }}>
              <div className={"quiz_cntr"}>{valuebutton}</div>

              {Object.keys(filteredData).map(
                function(key, index) {
                  let quesClass = "",
                    next = "block";
                  if (key == 0 && filteredData[key].question_details !== null) {
                    // let keyValue = Math.random();
                    let questionTitle = filteredData[key].question_details.title;
                    //questionTitle = questionTitle.replaceAll("<", "&lt;");

                    //questionTitle = questionTitle.replaceAll(">", "&gt;");
                    return (
                      <div
                        key={index}
                        className={"clear_section"}
                        id={filteredData[key].question_details.id}
                      >
                        <div className="quiz_title">
                          <h4>
                            <span className="question_no">
                              {this.state.curentQuestionNo}
                            </span>
                            {htmlParser(questionTitle)}
                          </h4>
                          {filteredData[key].question_details.type_id == 11 ||
                          filteredData[key].question_details.type_id == 12 ? (
                            <div>
                              <div className="form-group">
                                <textarea
                                  rows="3"
                                  ref={this.Inputtext}
                                  className="form-control"
                                  value={this.state.editorState}
                                  name="editorState"
                                  placeholder="Write Answer here"
                                  onChange={this.onEditorStateChange}
                                ></textarea>
                              </div>
                              {/* <div className="form-group">
                                  <label
                                    for="myfile"
                                    className="profile-top-label"
                                  >
                                    Upload:
                                  </label>
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="myfile"
                                    style={{ display: "block" }}
                                    onChange={this.onFileChange}
                                  />
                                </div> */}
                            </div>
                          ) : (
                              
                              <div className={"quiz_answer"}>
                                {this.state.optionError == 1 ?
                                  <div class="alert alert-danger" style={{ fontSize: '14px', width:'350px' }}>Please select an option. </div>
                                  : ''
                                }
                                
                              {filteredData[key] &&
                                filteredData[key].question_details &&
                                filteredData[key].question_details.options &&
                                Object.keys(
                                  filteredData[key].question_details.options
                                ).map(
                                  function(optionKey) {
                                    
                                    if (filteredData[key].question_details
                                      .options[optionKey] != '' &&
                                      filteredData[key].question_details
                                        .options[optionKey] != null &&
                                      filteredData[key].question_details
                                        .options[optionKey] != "undefined") {
                                        
                                      let optionValue = filteredData[key].question_details
                                        .options[optionKey];
                                      
  
                                      let newOptionValue = optionValue;
                                      
                                      if (optionValue != null && optionValue.search("--") > 0 &&
                                        optionValue.search("--!&gt;") < 0 &&
                                        optionValue.search("--&gt;") < 0 &&
                                        optionValue.search("!--") < 0) {
                                        newOptionValue = optionValue.replace("--", "<br />");
                                      }
                                      
                                    return (
                                      <div
                                        key={
                                          filteredData[key].question_details
                                            .options[optionKey]
                                        }
                                        className={"quiz_option"}
                                      >
                                        <div className="quiz_checkbox">
                                          <input
                                            type="radio"
                                            value={parseInt(optionKey) + 1}
                                            onChange={(e) =>
                                              this.setState({
                                                answer: e.target.value,
                                                optionError:0
                                              })
                                            }
                                            style={{ float: "left" }}
                                            name={"answers"}
                                            checked={
                                              this.state.answer ==
                                              parseInt(optionKey) + 1
                                            }
                                          />
                                          <p>
                                            {htmlParser(newOptionValue)}
                                           
                                          </p>
                                        </div>
                                      </div>
                                      );
                                    }

                                  }.bind(this)
                                )}
                            </div>
                          )}
                        </div>

                        {/*  */}

                        {!this.state.lastQuestion && (
                          <div className="submit-box">
                            <button
                              type="button"
                              className="btn-complier"
                              onClick={this.handleCheckAnswer}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                        <div>
                          {this.state.lastQuestion ? (
                            <div className="submit-box">
                              <button
                                type="button"
                                className="btn-complier"
                                onClick={this.handleCheckAnswer}
                              >
                                Submit
                              </button>

                              <button
                                data-quizid={filteredData[key].quiz_id}
                                data-id={filteredData[key].question_id}
                                data-entityid={this.props.filteredData.id}
                                type="button"
                                className="btn-complier"
                                onClick={this.submitQuiz}
                              >
                                {"End Quiz"}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className={"col-md-12 md_quiz_left"}>
                          <div>
                            {!this.state.firstQuestion && (
                              <span
                                data-quizid={filteredData[key].quiz_id}
                                data-id={filteredData[key].question_id}
                                className={"quiz_nex_btn"}
                                onClick={this.prevQuestion.bind(this)}
                              >
                                <i className="fas fa-chevron-left"></i>
                              </span>
                            )}
                          </div>
                          <div className="quiz_count">
                            <h3>
                              <span id={"current_question"}>
                                {this.state.curentQuestionNo}
                              </span>{" "}
                              / <span>{this.state.total_questions}</span>
                            </h3>
                            {/*<div className="row">*/}
                            {/*<div className="col-lg-12">*/}
                            {/*<div className="quiz_box check_space">*/}
                            {/*<h3>Questions <span*/}
                            {/*id={"current_question"}>{this.state.curentQuestionNo}</span> of <span>{6}</span></h3>*/}

                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                          </div>

                          <div>
                            {!this.state.lastQuestion && (
                              <span
                                data-questionno={parseInt(key) + 2}
                                data-quizid={filteredData[key].quiz_id}
                                data-id={filteredData[key].question_id}
                                className={"quiz_nex_btn"}
                                onClick={this.nextQuestion.bind(this)}
                              >
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                }.bind(this)
              )}
              {/*<button style={{'float' : 'right'}}  type="button" onClick={this.submitQuiz} className="btn btn-primary">{"Submit Quiz"}</button>*/}
            </form>
          ) : this.state.submitted ? (
            <div className={"quiz_cntr quiz_cntr_full"}>
              <div className={"alert alert-success"}>
                 You have successfully attempted the quiz.
              </div>
              <div
                style={{
                  color: "#284272",
                  fontSize: "23px",
                  marginBottom: "10px",
                }}
              >
                {/* You have got {this.state.obtain_marks} out of{" "}
                {this.state.total_marks}. */}
              </div>
              {/* <Link
                to={`/dashboard/c=${milliseconds}`}
                className={"btn btn-blueColor"}
              >
                Back to section
              </Link> */}
            </div>
          ) : (
            ""
          )
        ) : (
          <div className={"quiz_cntr quiz_cntr_full"}>
            <div
              className={"alert alert-danger"}
              style={{ fontSize: "medium" }}
            >
              It seems that you are offline. Please check your internet
              connection
            </div>
          </div>
        )}

        {/*  */}
        {this.state.isModalVisible && (
          <div className="modal fade show">
            <div class="modal-dialog modal-sm wrong-dialog">
              <div class="modal-content">
                <div class="modal-body popup-box wrong-box">
                  <div>
                    {this.state.wrongAnswerMsg == "Correct" && (
                      <h3 className="right_answer1">
                        {this.state.wrongAnswerMsg}
                      </h3>
                    )}
                    {this.state.wrongAnswerMsg == "nextQuestion" && (
                      <h3 className="right_answer1">
                        {this.state.correctAnswerIs}
                      </h3>
                    )}

                    {this.state.wrongAnswerMsg == "Incorrect" && (
                      <>
                        <h3 className="wrong_answer">
                          {this.state.wrongAnswerMsg}
                        </h3>
                        <p>
                         The correct answer is: <span>{htmlParser(this.state.correctAnswerIs)}</span>
                        </p>
                      </>
                    )}
                  </div>

                  <button class="btn btn-complete" onClick={this.handleClose}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*  */}
      </section>
    );
  }
}
