import { dashboard, header, compilerSection } from "../config";

class DashboardApi {
  getCourses = async (userid) => {
    let courseCategoryUrl = dashboard.userCourses;
    var milliseconds = new Date().getTime();
    return await fetch(`${courseCategoryUrl}?c=${milliseconds}`, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        Object.assign({}, res);
        return res;
      })
      .catch((error) => ({ error }));
  };

  getSectionCompilerList = async () => {
    var milliseconds = new Date().getTime();
    let compilerSectionUrl = `${compilerSection.compilerSections}?c=${milliseconds}`;

    return await fetch(compilerSectionUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        Object.assign({}, res);
        return res;
      })
      .catch((error) => ({ error }));
  };

  getInstructorCourses = async () => {
    let courseCategoryUrl = dashboard.instructorCourses;

    return await fetch(courseCategoryUrl, {
      method: "get",
      headers: header,
    })
      .then((response) => response.json())
      .then((res) => {
        Object.assign({}, res);
        return res;
      })
      .catch((error) => ({ error }));
  };
}
export default new DashboardApi();
