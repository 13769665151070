import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import DashboardApi from "../../helpers/dashboardApi";

import actions from "./actions";

export function* getCourseRequest() {
  yield takeEvery(actions.USER_COURSES, function*({ payload }) {
    try {
      const result = yield call(DashboardApi.getCourses, payload.userid);

      if (result.status_code === 200) {
        yield put(actions.userCoursesSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getSectionList() {
  yield takeEvery(actions.SECTION_COMPILER_LIST, function*({ payload }) {
    try {
      const result = yield call(DashboardApi.getSectionCompilerList, payload);
      if (result.status_code === 200) {
        yield put(actions.sectionListSuccess(result.data));
      } else {
        yield put(actions.sectionListSuccess(result));
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export function* getInstructorCourseRequest() {
  yield takeEvery(actions.INSTRUCTOR_COURSES, function*({ payload }) {
    try {
      const result = yield call(DashboardApi.getInstructorCourses, payload);

      if (result.status_code === 200) {
        yield put(actions.instructorCoursesSuccess(result.data));
      } else {
      }
    } catch (error) {
      console.log("error error");
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getCourseRequest)]);
  yield all([fork(getInstructorCourseRequest)]);
  yield all([fork(getSectionList)]);
}
