import React, { Component } from "react";
import { course, header } from "../../config";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { siteUrl } from "../../siteConfig";
class CompilerQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionNumber: 0,
      isModalVisible: false,
      programValue: "",
      htmlsrc: "",
      btnText: 'expandConatiner',
      leftPanelClass: 'col-lg-7 col-md-7',
      rightPanelClass: 'col-lg-5 col-md-5',
      showToolTip:0,

    };
  }

  prevQuestion = () => {
    if (this.state.questionNumber + 1 > 1) {
      this.setState(
        {
          questionNumber: this.state.questionNumber - 1,
          isModalVisible: false,
          htmlsrc: "",
        },
        () => {
          this.setState({
            programValue: ReactHtmlParser(
              this.props.data.unit_question[this.state.questionNumber]
                .default_program
            ),
          });
        }
      );
    }
  };

  componentDidMount() {
    const { data } = this.props;

    const { questionNumber } = this.state;
    if (this.props.data.current_page != null) {
      this.setState({
        questionNumber: parseInt(this.props.data.current_page) - 1,
      });
    } else {
      this.setState({ questionNumber: this.state.questionNumber });
    }

    if (this.state.programValue == "" && data.current_page && data.unit_question[parseInt(data.current_page - 1)].default_program != "undefined") {
      this.setState({
        programValue: ReactHtmlParser(
          data.unit_question[parseInt(data.current_page - 1)].default_program
        ),
      });
    } else if (this.state.programValue == "" && !data.current_page) {
      this.setState({
        programValue: ReactHtmlParser(
          data.unit_question[questionNumber].default_program
        ),
      });
    }
    if (data.unit_question.length == 1) {
      const progressdata = {
        unit_id: data.id,
        current_page: 1,
        total_page: data.unit_question.length,
      };

      var milliseconds = new Date().getTime();
debugger;
      axios
        .post(`${course.sectionUnitProgress}?c=${milliseconds}`, progressdata, {

		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("u$tOken"),
		},
        })
        .then((res) => (console.log()));
    }
  }

  nextQuestion = () => {
    const { data } = this.props;
    if (this.state.questionNumber + 1 < this.props.data.unit_question.length) {
      this.setState(
        {
          questionNumber: this.state.questionNumber + 1,
          htmlsrc: "",
        },
        () =>
          this.courseProgress(
            data.id,
            this.state.questionNumber,
            this.props.data.unit_question.length
          )
      );
    } else if (
      this.state.questionNumber + 1 ==
      this.props.data.unit_question.length
    ) {
      this.setState({ isModalVisible: true });
    }
  };

  courseProgress = (unitId, currentPage, totalpage) => {
    const { data } = this.props;
    const progressdata = {
      unit_id: unitId,
      current_page: currentPage + 1,
      total_page: totalpage,
    };
    this.setState({
      programValue: ReactHtmlParser(
        data.unit_question[this.state.questionNumber].default_program
      ),
    });
    var milliseconds = new Date().getTime();
    axios
      .post(`${course.sectionUnitProgress}?c=${milliseconds}`, progressdata, {
        
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("u$tOken"),
		},
      })
      .then((res) => console.log());
  };

  handleChange = (event) => {
    this.setState({ programValue: event.target.value });
  };
  
  handleTollTip = (event) => {
    if (event.type == 'mouseenter') {
      this.setState({ showToolTip: 1 });
    } else if (event.type == 'mouseleave') {
      this.setState({showToolTip:0})
    }
  }
  

  handleScreenToggle = (event) => {
    var element = document.getElementById("programmingQuestion");
    if (element.className.indexOf('col-lg-7') > -1) {
      this.setState({
        leftPanelClass: 'col-lg-12 col-md-12',
        rightPanelClass: 'col-lg-12 col-md-12',
      });
      this.setState({ btnText: 'collapseContainer' });
    } else {
      this.setState({
        leftPanelClass: 'col-lg-7 col-md-7',
        rightPanelClass: 'col-lg-5 col-md-5',
      });
      this.setState({ btnText: 'expandConatiner' });
    }
  };

  handleExecute = () => {
    var milliseconds = new Date().getTime();

    const htmldata = {
      content: Array.isArray(this.state.programValue)
        ? this.state.programValue[0]
        : this.state.programValue,
    };
    axios
      .post(`${course.creataeHtmlFile}?c=${milliseconds}`, htmldata, {
        headers: header,
      })
      .then((res) => {
        this.setState({ htmlsrc: res.data.data });
      })
      .catch((err) => {
        this.props.htmlData(err);
      });
  };
  render() {
    const { data } = this.props;
    const { questionNumber, programValue, htmlsrc } = this.state;
    var milliseconds = new Date().getTime();
    let headingTitle = data.title;
    const renderIframe = () => {
      let url_string = `${siteUrl.site_url}compilerPlateform`;
      var adsURL = "";

     
      if (data.type !== 9) {
        if (this.props.data.unit_question[this.state.questionNumber].id != "") {
          adsURL =
            url_string +
            "?unitId=" +
            data.id +
            "&questionno=" +
            data.unit_question[this.state.questionNumber].id;
        } else if (data.current_page) {
          var current_pageIs = parseInt(data.current_page);
          adsURL =
            url_string +
            "?unitId=" +
            data.id +
            "&questionno=" +
            data.unit_question[current_pageIs - 1].id;
        } else {
          adsURL =
            url_string +
            "?unitId=" +
            data.id +
            "&questionno=" +
            data.unit_question[0].id;
        }
      }

      return data.type == 1 ? (
        <iframe
          allowFullScreen
          frameBorder="0"
          title="unit details"
          scrolling="no"
          id="iframe"
          src={adsURL}
          style={{
            height: "518px",
            width: "100%",
            border: "none",
            overflow: "hidden",
          }}
        ></iframe>
      ) : (
        <iframe
          allowFullScreen
          frameBorder="0"
          title="unit details"
          scrolling="no"
          id="iframe"
          src={url_string}
          style={{
            height: "484px",
            width: "100%",
            border: "none",
            overflow: "hidden",
          }}
        ></iframe>
      );
    };

    const renderHtmlIframe = () => {
      return (
        <>
          <h3 className="htmlOutput">Output</h3>
        <div className="code-wrap1">
         
          
          <iframe
            allowFullScreen
            frameBorder="0"
            title="unit details"
            scrolling="yes"
            id="iframe"
            src={htmlsrc}
            style={{
              height: "451px",
              width: "100%",
              border: "none",
              overflow: "auto",
            }}
          ></iframe>
          </div>
          </>
      );
    };
    return (
      <React.Fragment>
        
        <div className={this.state.leftPanelClass} id="programmingQuestion">
          
          <div className="compiler-details"
             >
            <button className="collapseButton"
              onClick={this.handleScreenToggle}
              onMouseEnter={this.handleTollTip}
              onMouseLeave={this.handleTollTip}

              id="compilerToggleBtn" className={this.state.btnText} ></button>
            {
              this.state.showToolTip ?
                <div className="hintTooltip">
                  <span>Collapse/Expand</span>
                </div>
                : null


            }
            

            {data.unit_question[questionNumber].change_in_default_program ==
            1 ? (
              <React.Fragment>
                  <h2>{ReactHtmlParser(data.title)} </h2>
                  
                {data && (
                    <div className="que-box">
                     
                      <div className="que-head">

                      {questionNumber + 1}.{" "}
                      {data.unit_question[questionNumber] &&
                          ReactHtmlParser(data.unit_question[questionNumber].title)} 
                    </div>
                    <div className="que-head">
                      <textarea
                        className="custom-textarea"
                        row="10"
                        col="10"
                        style={{ height: "250px" }}
                        onChange={this.handleChange}
                        value={programValue}
                      ></textarea>
                      </div>
                      

                      {
                        (headingTitle.search("Assignment Level") > 0 ?

                          <div className="assistedHint">Type your code and click on the Execute button. </div>
                          :
                          <div className="assistedHint">Check Yourself ! Try changing the content in the code as per the instructions and run the code by clicking on the Execute Button. </div>


                        )}
                    
                      
                    <div className="execute-btn1">
                      <button
                        type="button"
                        onClick={this.handleExecute}
                        className="excuteButton"
                      >
                        Execute
                      </button>
                    </div>
                    {/* )} */}
                    <div className="possible-solution-box">
                      <p></p>
                      <div className="possiblesolution-img">
                        <img src="" className="img-responsive" alt="" />
                      </div>
                      <div className="flowchart-img">
                        <img src="" className="img-responsive" alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                  <h2>{data.title}</h2>
                  
                {data && (
                  <div className="que-box">
                    <div className="que-head">
                      {questionNumber + 1}.{" "}
                      {data.unit_question[questionNumber] &&
                        ReactHtmlParser(data.unit_question[questionNumber].title)}
                    </div>
                    <div className="que-head">
                      <div className="custom-textarea">
                        {/* {programValue} */}
                        {programValue != "" && (
                          <textarea
                            className="custom-textarea"
                            row="10"
                            col="10"
                            style={{ height: "250px" }}
                            value={programValue}
                          ></textarea>
                        )}
                      </div>
                    </div>
                    <div className="possible-solution-box">
                      <p></p>
                      <div className="possiblesolution-img">
                        <img src="" className="img-responsive" alt="" />
                      </div>
                      <div className="flowchart-img">
                        <img src="" className="img-responsive" alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            <ul className="custom-pagination">
              <li>
                {questionNumber + 1 != 1 && (
                  <span className="custom-link" onClick={this.prevQuestion}>
                    <i className="fas fa-chevron-left"></i>
                  </span>
                )}
              </li>
              <li>
                <span>{questionNumber + 1}</span>/{data.unit_question.length}
              </li>
              <li>
                {data.unit_question.length != 1 &&
                  data.unit_question.length != questionNumber + 1 && (
                    <span className="custom-link" onClick={this.nextQuestion}>
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  )}
              </li>
            </ul>
          </div>
        </div>
        {data.unit_question[questionNumber].change_in_default_program == 1 ? (
          <div className={this.state.rightPanelClass} id="flowChartArea">
            
            <div className="compiler-code">{renderHtmlIframe()}</div>
          </div>
        ) : (
            <div className={this.state.rightPanelClass} id="flowChartArea">
              
              <div className="compiler-code">{renderIframe()}</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CompilerQuestion;
