import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthAction from "../../redux/auth/actions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: "",
      formIsValid: true,
      errormessage: "",
      isLoadingVisible: "none",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleValidate = () => {
    let errors = {};

    let formIsValid = true;
    this.setState({ errors });
    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "Email";
    } else if (this.state.password == "") {
      formIsValid = false;
      errors["password"] = "Password";
    }
    this.setState({ formIsValid });
    return formIsValid;
  };

  handleLogin = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    if (this.handleValidate()) {
      this.props.login({ email, password });
      // this.setState({ isLoadingVisible: "block" });
    }
  };

  handleSignUp = () => {
    this.props.history.push("/signup");
  };

  componentDidUpdate(prevProps) {
    var milliseconds = new Date().getTime();
    if (prevProps.isTokenResponse != this.props.isTokenResponse) {
      if (this.props.isError && this.props.isError.status_code == 404) {
        this.setState({ errormessage: this.props.isError.message });
        setTimeout(() => {
          this.setState({ errormessage: "" });
        }, 3000);
      } else if (this.props.isTokenResponse) {
        this.props.history.push(`/dashboard/c=${milliseconds}`);
        this.setState({ email: "", password: "" });
      }
    }
  }

  render() {
    const {
      errormessage,
      isLoadingVisible,
      formIsValid,
      email,
      password,
      errors,
    } = this.state;
    return (
      <>
        <header>
          <nav
            className="navbar navbar-expand-md custom-navbar login-bg"
            id="headerdiv"
          >
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img
                  src="/dist/img/logo.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </a>
            </div>
          </nav>
        </header>
        {/* loader */}
        <div className="modal" style={{ display: isLoadingVisible }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body loaderBox" style={{ margin: "auto" }}>
                <h3>Please wait...</h3>
                <div className="loader"></div>
              </div>
            </div>
          </div>
        </div>
        {/* loader ends */}
        <div className="login-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="login-box">
                  <div className="leftSec">
                    <img
                      src="/dist/img/login.png"
                      className="img-fluid"
                      alt="login Image"
                    />
                  </div>
                  <div className="rightSec">
                    <form onSubmit={this.handleLogin}>
                      <h2>Login Here</h2>
                      <span className="error-msg">{errormessage}</span>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>E-Mail Address</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.email == "Email" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Password</label>
                            <div
                              className={`input-group ${!formIsValid &&
                                errors.password == "Password" &&
                                "warning_box_color"}`}
                            >
                              <span>
                                <i className="fas fa-lock"></i>
                              </span>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="other-info">
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck"
                                  name="example1"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customCheck"
                                >
                                  Remember Me
                                </label>
                              </div> */}
                              <div className="forgot-password">
                                <Link to="/forgot_password">
                                  Forgot Password ?
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn-login"
                            onClick={this.handleLogin}
                          >
                            Login
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <p className="new-user">
                            New here?
                            <span onClick={this.handleSignUp}>Sign Up</span>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default connect(
  (state) => ({
    state,
    isTokenResponse: state.Auth.idToken,
    isError: state.Auth.error,
  }),
  { ...AuthAction }
)(Login);
