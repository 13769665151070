import actions from "./actions";

const initState = {
  idToken: null,
  success: false,
  signupData: null,
  isSignup: false,
  forgotdata: null,
  error: null,
  resetdata: null,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.payload && action.payload.result,
        error:
          action.payload && action.payload.result && action.payload.result.data,
        success: true,
      };
    case actions.LOGOUT:
      return initState;
    case actions.SIGNUP_SUCCESS:
      return { ...state, signupData: action.payload.result, isSignup: true };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotdata: action.payload.result };
    case actions.RESET_PASSWORD_SUCCESS:
      return { ...state, resetdata: action.payload.result };

    default:
      return state;
  }
}
